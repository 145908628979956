import { Component } from '@angular/core';

@Component({
  selector: 'app-gaimin-gg',
  template:'',
  styles: ''
})
export class GaiminGgComponent {
  constructor() {
    window.location.href = "https://gaimin.gg"
  }
}
