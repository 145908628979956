import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, interval, Observable, of } from 'rxjs';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import {
  Address,
  Chain,
  Config,
  disconnect,
  GetAccountResult,
  switchNetwork,
  watchAccount,
  watchNetwork
} from '@wagmi/core';
import { Web3Modal } from '@web3modal/wagmi/dist/types/src/client';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { ToastrService } from 'ngx-toastr';
import { GenesisNftsService } from './genesis-nfts.service';
import { GenesisClaimService } from './genesis-claim.service';
import { GenesisErc721Service } from './genesis-erc721.service';
import { ModalService } from '../modal.service';
import nftsData from './nfts.data';
import {
  ClaimedUserNFT,
  ClaimedUserNFTs,
  NFT,
  NFTs,
  SelectedNftsData,
  TokenId,
  UserNFT,
  UserNFTs,
  UserNftsData,
  WalletData
} from './genesis.interfaces';
import { environment } from '../../../../environments/environment';
import { EVENT_TYPE } from './genesis.enums';

@Injectable({
  providedIn: 'root'
})
export class GenesisService {
  private readonly ACTIVE_CHAIN: Chain = environment.genesis.chain;
  private readonly PROJECT_ID: string = 'f3c4c117e79af11c9a5063ba89abb8f3';
  private readonly WALLET_LIST: string[] = [
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    'a21d06c656c8b1de253686e06fc2f1b3d4aa39c46df2bfda8a6cc524ef32c20c',
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
    '2a3c89040ac3b723a1972a33a125b1db11e258a6975d3a61252cd64e6ea5ea01',
    '7674bb4e353bf52886768a3ddc2a4562ce2f4191c80831291218ebd90f5f5e26',
    '0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150',
    '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',
    '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
    'dceb063851b1833cbb209e3717a0a0b06bf3fb500fe9db8cd3a553e4b1d02137',
    '0f5eca2c7f2c9d11c992fdc707575c484ffb751e58e43eaeeea24510bfe8b8dd',
    '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927',
    '3fecad5e2f0a30aba97edea69ebf015884a9b8a9aec93e66d4b4b695fee1f010'
  ];
  private readonly CHECK_APPROVAL_STATUS_INTERVAL: number = 1000 * 3; // 3 sec
  private readonly nftsData: NFTs = nftsData;

  private readonly web3Modal: Web3Modal = this.getConfiguredWeb3Modal();

  private activeChainId$$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  get activeChainId$() {
    return this.activeChainId$$.asObservable();
  }

  public wallet: WalletData = {
    address$: new BehaviorSubject<Address | undefined>(undefined),
    isConnected: false
  };

  userNfts: UserNftsData = {
    nfts: [],
    lockedNfts: [],
    unlockedNfts: []
  };

  selected: SelectedNftsData = {
    lockedIds: [],
    unlockedIds: []
  };

  get walletAddress(): Address | undefined {
    return this.wallet.address$.getValue();
  }

  constructor(
    private toastrService: ToastrService,
    private modalService: ModalService,
    private genesisNftsService: GenesisNftsService,
    private genesisClaimService: GenesisClaimService,
    private genesisErc721Service: GenesisErc721Service
  ) {
    watchAccount((account: GetAccountResult) => {
      this.wallet.address$.next(account.address ? account.address : undefined);
      this.wallet.isConnected = account.isConnected;

      if (account.isConnected) {
        console.log('Wallet connected! Address:', this.walletAddress);
      } else {
        console.log('Wallet disconnected!');
      }
    });

    watchNetwork((network) => {
      this.activeChainId$$.next(network.chain?.id || 0);

      // Handle disconnect wallet
      if (!network.chain) {
        return;
      }

      const isActiveChainCorrect = !!network.chains.find((chain): boolean => chain.id === network.chain?.id);

      if (!isActiveChainCorrect) {
        this.modalService.openSwitchChainModal(this.ACTIVE_CHAIN.name, this.ACTIVE_CHAIN.id);
      }
    });
  }

  openConnectWalletModal() {
    return this.web3Modal.open();
  }

  disconnectWallet() {
    return disconnect();
  }

  switchNetwork(chainId: number = this.ACTIVE_CHAIN.id) {
    return switchNetwork({ chainId });
  }

  claimNfts() {
    return this.approveClaimNfts().pipe(
      switchMap(() => {
        return this.genesisClaimService.claim();
      })
    );
  }

  lockNfts(nftIds: number[], walletAddress: Address = this.walletAddress!) {
    return this.approveLockNfts().pipe(
      switchMap(() => {
        return this.genesisClaimService.lock(nftIds).pipe(
          tap(() => this.toastrService.success('Request for locking nfts has been sent! It might take a few seconds!')),
          switchMap(() => {
            return this.waitUntilTransactionEventAndUpdateUserNfts(EVENT_TYPE.LOCK, walletAddress);
          })
        );
      }),
      catchError((err) => {
        this.toastrService.error(err.message);
        return of(false);
      })
    );
  }

  unlock(nftIds: number[], walletAddress: Address = this.walletAddress!) {
    return this.genesisClaimService.unlock(nftIds).pipe(
      tap(() => this.toastrService.success('Request for unlocking nfts has been sent! It might take a few seconds!')),
      switchMap(() => {
        return this.waitUntilTransactionEventAndUpdateUserNfts(EVENT_TYPE.UNLOCK, walletAddress);
      }),
      catchError((err) => {
        this.toastrService.error(err.message);
        return of(false);
      })
    );
  }

  unlockAll(walletAddress: Address = this.walletAddress!) {
    return this.genesisClaimService.unlockAll().pipe(
      tap(() =>
        this.toastrService.success('Request for unlocking whole nfts has been sent! It might take a few seconds!')
      ),
      switchMap(() => {
        return this.waitUntilTransactionEventAndUpdateUserNfts(EVENT_TYPE.UNLOCK_ALL, walletAddress);
      }),
      catchError((err) => {
        this.toastrService.error(err.message);
        return of(false);
      })
    );
  }

  lockAll(walletAddress: Address = this.walletAddress!) {
    const unlockedNftIds = this.userNfts.unlockedNfts.map((nft) => nft.claimedNftId);

    return this.genesisClaimService.lock(unlockedNftIds).pipe(
      tap(() =>
        this.toastrService.success('Request for locking whole nfts has been sent! It might take a few seconds!')
      ),
      switchMap(() => {
        return this.waitUntilTransactionEventAndUpdateUserNfts(EVENT_TYPE.LOCK, walletAddress);
      }),
      catchError((err) => {
        this.toastrService.error(err.message);
        return of(false);
      })
    );
  }

  /**
   * Get all not claimed user NFTs
   *
   * @param walletAddress Wallet address to get not claimed NFTs
   *
   * @return {UserNFTs} User nfts
   */
  getNfts(walletAddress: Address = this.walletAddress!): Observable<UserNFTs> {
    return this.genesisNftsService.balanceOfBatch(walletAddress).pipe(
      switchMap((countNfts: bigint[]) => {
        const userNfts: UserNFT[] = [];

        this.nftsData.forEach((nft: NFT) => {
          const count: number = Number(countNfts[nft.tokenId - 1]);

          if (count && count > 0) {
            userNfts.push({ ...nft, count });
          }
        });

        return of(userNfts);
      })
    );
  }

  /**
   * Gets all locked NFTs from claim smart contract
   *
   * @param walletAddress Wallet address to get locked nfts
   *
   * @return {ClaimedUserNFTs} - Locked user nfts with metadata
   */
  getLockedNfts(walletAddress: Address = this.walletAddress!): Observable<ClaimedUserNFTs> {
    return this.genesisClaimService.getUserNftIds(walletAddress).pipe(
      switchMap((rawNftIds: bigint[]) => {
        const nftIds: string[] = rawNftIds.map((rawNftId: bigint): string => `${rawNftId}`);

        const lockedNfts: ClaimedUserNFTs = nftIds.map((nftId: string): ClaimedUserNFT => {
          const tokenId: TokenId = +nftId[0] as TokenId;
          return { ...this.getNftByTokenId(tokenId), claimedNftId: +nftId, isLocked: true };
        });

        return of(lockedNfts);
      })
    );
  }

  /**
   * Gets all unlocked NFTs from erc721 smart contract
   *
   * @param walletAddress Wallet address to get unlocked nfts
   *
   * @return {ClaimedUserNFTs} - Unlocked user nfts with metadata
   */
  getUnlockedNfts(walletAddress: Address = this.walletAddress!): Observable<ClaimedUserNFTs> {
    return this.genesisErc721Service.getUnlockedNftIds(walletAddress).pipe(
      switchMap((rawNftIds: bigint[]) => {
        const nftIds: string[] = rawNftIds.map((rawNftId: bigint): string => `${rawNftId}`);

        const unlockedNfts: ClaimedUserNFTs = nftIds.map((nftId: string): ClaimedUserNFT => {
          const tokenId: TokenId = +nftId[0] as TokenId;
          return { ...this.getNftByTokenId(tokenId), claimedNftId: +nftId, isLocked: false };
        });

        return of(unlockedNfts);
      })
    );
  }

  updateUserNftsData(walletAddress: Address = this.walletAddress!) {
    return forkJoin([
      this.getNfts(walletAddress),
      this.getLockedNfts(walletAddress),
      this.getUnlockedNfts(walletAddress)
    ]).pipe(
      tap(([nfts, lockedNfts, unlockedNfts]) => {
        this.userNfts = {
          nfts,
          lockedNfts,
          unlockedNfts
        };

        this.selected = {
          lockedIds: [],
          unlockedIds: []
        };
      })
    );
  }

  waitUntilTransactionEventAndUpdateUserNfts(eventType: EVENT_TYPE, walletAddress: Address = this.walletAddress!) {
    return this.genesisClaimService.watchContractEvent(eventType, walletAddress).pipe(
      tap((res) => console.log(res)),
      first(),
      switchMap(() => this.updateUserNftsData())
    );
  }

  /**
   * Get nft data by token id.
   *
   * @param tokenId Token id
   *
   * @return {NFT} - Return stored nft data
   */
  private getNftByTokenId(tokenId: TokenId): NFT {
    return this.nftsData.find((nft: NFT): boolean => nft.tokenId === tokenId)!;
  }

  /**
   * Use this function before sending "claim" request
   *
   * @param walletAddress User wallet address for check approval status
   * @param operator Smart contract address for approval nfts sending
   *
   * @return {true} Always returns "true" after change status in smart contract
   */
  private approveClaimNfts(
    walletAddress: Address = this.walletAddress!,
    operator: Address = this.genesisClaimService.SMART_CONTRACT_ADDRESS
  ): Observable<boolean> {
    return this.genesisNftsService.setApprovalForAll(true, walletAddress, operator).pipe(
      tap((response) => {
        // Shows message for user when it was disabled
        if (response) {
          this.toastrService.success('The request to approve sending NFTs has been sent! It might take a while.');
        }
      }),
      switchMap((response) => {
        // Wait until approval status change in smart contract
        return interval(this.CHECK_APPROVAL_STATUS_INTERVAL).pipe(
          switchMap(() => this.genesisNftsService.isApprovedForAll(walletAddress, operator)),
          tap((isApproved: boolean) => console.log('Is approved status:', isApproved, 'Must be: true')),
          first((isApproved: boolean): boolean => isApproved)
        );
      })
    );
  }

  /**
   * Use this function before sending "lock" request
   *
   * @param walletAddress User wallet address for check approval status
   * @param operator Smart contract address for approval nfts sending
   *
   * @return {true} Always returns "true" after change status in smart contract
   */
  private approveLockNfts(
    walletAddress: Address = this.walletAddress!,
    operator: Address = this.genesisClaimService.SMART_CONTRACT_ADDRESS
  ): Observable<boolean> {
    return this.genesisErc721Service.setApprovalForAll(true, walletAddress, operator).pipe(
      tap((response) => {
        // Shows message for user when it was disabled
        if (response) {
          this.toastrService.success('The request to approve sending NFTs has been sent! It might take a while.');
        }
      }),
      switchMap((response) => {
        // Wait until approval status change in smart contract
        return interval(this.CHECK_APPROVAL_STATUS_INTERVAL).pipe(
          switchMap(() => this.genesisErc721Service.isApprovedForAll(walletAddress, operator)),
          tap((isApproved: boolean) => console.log('Is approved status:', isApproved, 'Must be: true')),
          first((isApproved: boolean): boolean => isApproved)
        );
      })
    );
  }

  /**
   * @param chains Supported chains. First chain in list sets as default
   * @param walletIds List of available wallet ids to connect
   * @param projectId Project id
   *
   * @return {Web3Modal} Configured web3 modal
   */
  private getConfiguredWeb3Modal(
    chains: Chain[] = [this.ACTIVE_CHAIN],
    walletIds: string[] = this.WALLET_LIST,
    projectId: string = this.PROJECT_ID
  ): Web3Modal {
    const metadata = {
      name: 'Gaimin Genesis',
      description: 'Gaimin genesis page',
      url: 'https://pages.gaimin.gg'
    };

    const wagmiConfig: Config = defaultWagmiConfig({
      chains,
      projectId,
      metadata,
      enableCoinbase: false
    });

    return createWeb3Modal({
      wagmiConfig,
      projectId,
      defaultChain: chains[0],
      includeWalletIds: walletIds
    });
  }
}
