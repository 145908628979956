import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket!: WebSocket;
  private messageSubject = new Subject<string>();

  constructor() {
  }

  /**
   * Initializes a WebSocket connection to a server at the provided URL.
   * Sets up event listeners for messages, connection closure, and errors.
   */
  initWebSocket() {
    this.socket = new WebSocket('ws://localhost:8282');
    this.socket.addEventListener('message', (event) => {
      const message = event.data;
      this.messageSubject.next(message);

      if (message === 'closeWindow') {
        window.close();
      }
    });

    this.socket.addEventListener('close', (event) => {
      console.log('Server connection closed:', event);
    });

    this.socket.addEventListener('error', (error) => {
      console.error('WebSocket Error:', error);
    });
  }

  /**
   * Sends a message to the connected WebSocket server.
   *
   * @param message {string} The message to send.
   */
  sendMessage(message: string) {
    this.socket?.send(message);
  }


  /**
   * Provides an observable stream of messages received through the WebSocket connection.
   *
   * @returns {Observable<string>} An observable that emits received messages as strings.
   */
  onMessage(): Observable<string> {
    return this.messageSubject.asObservable();
  }

}
