import { Component } from '@angular/core';

@Component({
  selector: 'app-failed-page',
  templateUrl: './failed-page.component.html',
  styleUrls: ['./failed-page.component.scss']
})
export class FailedPageComponent {
  constructor() {}

  year: number = new Date().getFullYear();
}
