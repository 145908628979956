import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-stake-confirmation-modal',
  templateUrl: './stake-confirmation-modal.component.html',
  styleUrls: ['./stake-confirmation-modal.component.scss']
})
export class StakeConfirmationModalComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }


  closeModal() {
    this.activeModal.dismiss('Cross click');
  }

}
