import { Injectable } from '@angular/core';
import {
  PublicKey,
  SystemProgram,
  SYSVAR_INSTRUCTIONS_PUBKEY,
  TransactionInstruction
} from '@solana/web3.js';
import { SolanaPdaService } from './solana-pda.service';
import { SolanaConstantsService } from './tools/solana-constants.service';
import { SolanaService } from './solana.service';

@Injectable({
  providedIn: 'root'
})
export class SolanaInstructionService {

  constructor(private solanaPda: SolanaPdaService,
              private solanaConstants: SolanaConstantsService,
              private solanaService: SolanaService) {
  }

  /**
   * Creates a claim instruction for nft.
   *
   * @param userWallet - The user's wallet public key
   * @param nftMintAddress - The NFT mint address public key
   * @param claimSeed - The claim seed buffer
   *
   * @return {TransactionInstruction} - The transaction instruction
   */
  claimInstruction(userWallet: PublicKey, nftMintAddress: PublicKey, claimSeed: Buffer): TransactionInstruction {
    try {
      const [nftRecord, nftBump] = this.solanaPda.getNftPda(nftMintAddress);
      const [token, tokenBump] = this.solanaPda.getTokenAccountPda(nftMintAddress, userWallet);
      const [claim] = this.solanaPda.getClaimPda(userWallet, claimSeed);
      const config = this.solanaPda.getConfigPda()[0];
      const [tokenRecord, tokenRecordBump] = this.solanaPda.getTokenRecordPda(nftMintAddress, token);

      return new TransactionInstruction({
        data: Buffer.from(Uint8Array.of(
          this.solanaConstants.GAIMIN_PFP_INSTRUCTIONS.CLAIM,
          tokenBump,
          tokenRecordBump,
          nftBump
        )),
        keys: [
          { pubkey: userWallet, isSigner: true, isWritable: false },
          { pubkey: token, isSigner: false, isWritable: false },
          { pubkey: tokenRecord, isSigner: false, isWritable: false },
          { pubkey: nftRecord, isSigner: false, isWritable: true },
          { pubkey: claim, isSigner: false, isWritable: true },
          { pubkey: config, isSigner: false, isWritable: false }
        ],
        programId: this.solanaConstants.GAIMIN_PFP
      });
    } catch (error) {
      console.error('Error in claimInstruction:', error);
      this.solanaService.isLoading.next(false);
      throw new Error('Error in claimInstruction');
    }
  }

  /**
   * Creates a claim instruction for  bnb wallet.
   *
   * @param wallet - The wallet public key
   * @param bnbWallet - The BNB wallet string
   * @param claimSeed - The claim seed buffer
   *
   * @return {TransactionInstruction} - The transaction instruction
   */
  createClaimInstruction(wallet: PublicKey, bnbWallet: string, claimSeed: Buffer): TransactionInstruction {
    const enc = new TextEncoder();
    const [claim, bump] = this.solanaPda.getClaimPda(wallet, claimSeed);
    const config = this.solanaPda.getConfigPda()[0];

    return new TransactionInstruction({
      data: Buffer.from(Uint8Array
      .of(this.solanaConstants.GAIMIN_PFP_INSTRUCTIONS.CREATE_CLAIM, bump, ...claimSeed, ...enc.encode(bnbWallet.slice(2)))),
      keys: [
        { pubkey: wallet, isSigner: true, isWritable: false },
        { pubkey: claim, isSigner: false, isWritable: true },
        { pubkey: config, isSigner: false, isWritable: false },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false }
      ],
      programId: this.solanaConstants.GAIMIN_PFP
    });
  }

  /**
   * Registers an NFT instruction.
   *
   * @param signer - The signer public key
   * @param mintAddress - The mint address public key
   *
   * @return {TransactionInstruction} - The transaction instruction
   */
  registerNftInstruction(signer: PublicKey, mintAddress: PublicKey): TransactionInstruction {
    const config = this.solanaPda.getConfigPda()[0];
    const nftPda = this.solanaPda.getNftPda(mintAddress)[0];
    const metaDataAccount = this.solanaPda.getMetadataAccountPda(mintAddress)[0];
    const masterEditionAccoun = this.solanaPda.getMasterEditionAccountPda(mintAddress)[0];
    return new TransactionInstruction({
      data: Buffer.from(Uint8Array.of(this.solanaConstants.GAIMIN_PFP_INSTRUCTIONS.NFT)),
      keys: [
        { pubkey: signer, isSigner: true, isWritable: false },
        { pubkey: mintAddress, isSigner: false, isWritable: false },
        { pubkey: metaDataAccount, isSigner: false, isWritable: false },
        { pubkey: masterEditionAccoun, isSigner: false, isWritable: false },
        { pubkey: nftPda, isSigner: false, isWritable: true },
        { pubkey: config, isSigner: false, isWritable: false },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false }
      ],
      programId: this.solanaConstants.GAIMIN_PFP
    });
  }

  /**
   * Delegates an approval instruction.
   *
   * @param mint - The mint public key
   * @param userWallet - The user's wallet public key
   * @param payer - The payer public key
   * @param delegate - The delegate public key
   *
   * @return {TransactionInstruction} - The transaction instruction
   */
  delegateApproveInstruction(mint: PublicKey, userWallet: PublicKey, payer: PublicKey, delegate: PublicKey): TransactionInstruction {
    const data = Buffer.allocUnsafe(11);
    data.writeInt8(this.solanaConstants.MPL_INSTRUCTIONS.delegate);
    data.writeInt8(this.solanaConstants.MPL_DELEGATE_TYPES.staking, 1);
    data.writeInt8(1, 2);
    data.writeInt8(0, 10);

    const tokenAcc = this.solanaPda.getTokenAccountPda(mint, userWallet)[0];
    const metadataAcc = this.solanaPda.getMetadataAccountPda(mint)[0];
    const masterEditionAcc = this.solanaPda.getMasterEditionAccountPda(mint)[0];
    const tokenRec = this.solanaPda.getTokenRecordPda(mint, tokenAcc)[0];

    return new TransactionInstruction({
      data,
      keys: [
        this.solanaConstants.MPL_ACCOUNT,
        { pubkey: delegate, isSigner: false, isWritable: false },
        { pubkey: metadataAcc, isSigner: false, isWritable: true },
        { pubkey: masterEditionAcc, isSigner: false, isWritable: false },
        { pubkey: tokenRec, isSigner: false, isWritable: true },
        { pubkey: mint, isSigner: false, isWritable: false },
        { pubkey: tokenAcc, isSigner: false, isWritable: true },
        { pubkey: userWallet, isSigner: true, isWritable: false },
        { pubkey: payer, isSigner: true, isWritable: false },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
        { pubkey: SYSVAR_INSTRUCTIONS_PUBKEY, isSigner: false, isWritable: false },
        { pubkey: this.solanaConstants.TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
        this.solanaConstants.MPL_ACCOUNT_AUTH,
        this.solanaConstants.MPL_ACCOUNT
      ],
      programId: this.solanaConstants.MPL_TOKEN_METADATA
    });
  }

  /**
   * Delegates a revoke instruction.
   *
   * @param mint - The mint public key
   * @param userWallet - The user's wallet public key
   * @param payer - The payer public key
   * @param delegate - The delegate public key
   *
   * @return {TransactionInstruction} - The transaction instruction
   */
  delegateRevokeInstruction(mint: PublicKey, userWallet: PublicKey, payer: PublicKey, delegate: PublicKey): TransactionInstruction {
    const tokenAcc = this.solanaPda.getTokenAccountPda(mint, userWallet)[0];
    const metadataAcc = this.solanaPda.getMetadataAccountPda(mint)[0];
    const masterEditionAcc = this.solanaPda.getMasterEditionAccountPda(mint)[0];
    const tokenRec = this.solanaPda.getTokenRecordPda(mint, tokenAcc)[0];

    return new TransactionInstruction({
      data: Buffer.from(Uint8Array.of(this.solanaConstants.MPL_INSTRUCTIONS.revoke, this.solanaConstants.MPL_DELEGATE_TYPES.staking)),
      keys: [
        this.solanaConstants.MPL_ACCOUNT,
        { pubkey: delegate, isSigner: false, isWritable: false },
        { pubkey: metadataAcc, isSigner: false, isWritable: true },
        { pubkey: masterEditionAcc, isSigner: false, isWritable: false },
        { pubkey: tokenRec, isSigner: false, isWritable: true },
        { pubkey: mint, isSigner: false, isWritable: false },
        { pubkey: tokenAcc, isSigner: false, isWritable: true },
        { pubkey: userWallet, isSigner: true, isWritable: false },
        { pubkey: payer, isSigner: true, isWritable: false },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
        { pubkey: SYSVAR_INSTRUCTIONS_PUBKEY, isSigner: false, isWritable: false },
        { pubkey: this.solanaConstants.TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
        this.solanaConstants.MPL_ACCOUNT_AUTH,
        this.solanaConstants.MPL_ACCOUNT
      ],
      programId: this.solanaConstants.MPL_TOKEN_METADATA
    });
  }

  /**
   * Creates a stake instruction.
   *
   * @param mplInsractionType - The MPL instruction type
   * @param mint - The mint public key
   * @param userWallet - The user's wallet public key
   * @param payer - The payer public key
   * @param delegate - The delegate public key
   *
   * @return {TransactionInstruction} - The transaction instruction
   */
  stakeInstruction(mplInsractionType: number, mint: PublicKey, userWallet: PublicKey, payer: PublicKey, delegate: PublicKey): TransactionInstruction {
    const tokenAcc = this.solanaPda.getTokenAccountPda(mint, userWallet)[0];
    const metadataAcc = this.solanaPda.getMetadataAccountPda(mint)[0];
    const masterEditionAcc = this.solanaPda.getMasterEditionAccountPda(mint)[0];
    const tokenRec = this.solanaPda.getTokenRecordPda(mint, tokenAcc)[0];

    return new TransactionInstruction({
      data: Buffer.from(Uint8Array.of(mplInsractionType, 0, 0)),
      keys: [
        { pubkey: delegate, isSigner: true, isWritable: false },
        this.solanaConstants.MPL_ACCOUNT,
        { pubkey: tokenAcc, isSigner: false, isWritable: true },
        { pubkey: mint, isSigner: false, isWritable: false },
        { pubkey: metadataAcc, isSigner: false, isWritable: true },
        { pubkey: masterEditionAcc, isSigner: false, isWritable: false },
        { pubkey: tokenRec, isSigner: false, isWritable: true },
        { pubkey: payer, isSigner: true, isWritable: true },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
        { pubkey: SYSVAR_INSTRUCTIONS_PUBKEY, isSigner: false, isWritable: false },
        { pubkey: this.solanaConstants.TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
        this.solanaConstants.MPL_ACCOUNT_AUTH,
        this.solanaConstants.MPL_ACCOUNT
      ],
      programId: this.solanaConstants.MPL_TOKEN_METADATA
    });
  }

}
