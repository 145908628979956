// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-solana-content .main__content-text-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
}
app-solana-content .main__content-button {
  max-width: 100%;
  display: inline-block;
  margin-top: 20px;
  color: #fff;
  text-align: left;
  letter-spacing: 0;
  text-transform: capitalize;
  background-color: #776bf8;
  border-radius: 90px;
  font-weight: bold;
  padding: 12px 16px;
  transition-duration: 0.5s;
  text-decoration: none;
  cursor: pointer;
}
app-solana-content .main__content-button:hover {
  background-color: #5047b4;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/solana-claim-nft/solana-claim-nft.component.scss"],"names":[],"mappings":"AAIQ;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAHV;AAOM;EACE,eAAA;EACA,qBAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,0BAAA;EACA,yBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,qBAAA;EACA,eAAA;AALR;AAOQ;EACE,yBAAA;AALV","sourcesContent":["app-solana-content {\n  .main {\n    &__content {\n      &-text {\n        &-title {\n          font-weight: 400;\n          font-size: 20px;\n          line-height: 27px;\n          color: #ffffff;\n        }\n      }\n\n      &-button {\n        max-width: 100%;\n        display: inline-block;\n        margin-top: 20px;\n        color: #fff;\n        text-align: left;\n        letter-spacing: 0;\n        text-transform: capitalize;\n        background-color: #776bf8;\n        border-radius: 90px;\n        font-weight: bold;\n        padding: 12px 16px;\n        transition-duration: 0.5s;\n        text-decoration: none;\n        cursor: pointer;\n\n        &:hover {\n          background-color: #5047b4;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
