import { Injectable } from '@angular/core';
import { AccountInfo, PublicKey } from '@solana/web3.js';
import { ConfigRecord, NftRecord, TokenRecord } from './tools/solana.interfaces';
import { SolanaConstantsService } from './tools/solana-constants.service';
import * as bs58 from 'bs58';

@Injectable({
  providedIn: 'root'
})
export class SolanaParsersService {

  constructor(private solanaConstants: SolanaConstantsService) {
  }

  /**
   * Parses the data buffer of a token account to extract relevant information.
   *
   * @param acc {PublicKey} The public key of the token account to parse.
   * @returns {Promise<TokenRecord>} A Promise that resolves to an object containing the parsed token data:
   *   - state: The state of the token account (e.g., initialized, frozen).
   *   - delegate: The delegate public key (if any).
   *   - delegateRole: The role of the delegate (if applicable).
   */
  async getAccountInfo(acc: PublicKey): Promise<AccountInfo<Buffer>> {
    return await this.solanaConstants.connection.getAccountInfo(acc)
    .then(resp => resp ?? Promise.reject('Uninitialized account'));
  }

  /**
   * Parses the data buffer of a token account to extract relevant information.
   *
   * @param acc {PublicKey} The public key of the token account to parse.
   * @returns {Promise<TokenRecord>} A Promise that resolves to an object containing the parsed token data:
   *   - state: The state of the token account (e.g., initialized, frozen).
   *   - delegate: The delegate public key (if any).
   *   - delegateRole: The role of the delegate (if applicable).
   */
  async parseTokenRecord(acc: PublicKey): Promise<TokenRecord> {
    const resp = await this.getAccountInfo(acc);
    let offset = 2;
    const state = resp.data.readInt8(offset++);
    offset += resp.data.readInt8(offset) === 0 ? 1 : 9;
    const delegate = resp.data.readInt8(offset) === 0 ? null : bs58.encode(resp.data.slice(offset + 1, offset + 33));
    const delegateRole = delegate == null ? null : resp.data.readInt8(offset + 34);
    return { state, delegate, delegateRole };
  }

  /**
   * Parses the data buffer of a configuration account to extract relevant information.
   *
   * @param configPda {PublicKey} The public key of the configuration account to parse.
   * @returns {Promise<ConfigRecord>} A Promise that resolves to an object containing the parsed configuration data:
   *   - authority: The public key of the account with authority over the configuration.
   *   - creator: The public key of the account that created the configuration.
   *   - claimable_from: The timestamp (in Unix seconds) from which claims can be made.
   *   - accumulated_reward: The total amount of reward accumulated so far.
   *   - initial_reward: The initial reward amount set for the configuration.
   *   - accumulation_duration: The duration (in seconds) for accumulating rewards.
   *   - generation_duration: The duration (in seconds) for generating new rewards.
   */
  async parseConfig(configPda: PublicKey): Promise<ConfigRecord> {
    return this.getAccountInfo(configPda).then((response) => {
      return {
        authority: new PublicKey(bs58.encode(response?.data.slice(0, 32)!)),
        creator: new PublicKey(bs58.encode(response?.data.slice(32, 64)!)),
        claimable_from: response?.data.readInt32LE(64),
        accumulated_reward: response?.data.readInt32LE(68)!,
        initial_reward: response?.data.readInt32LE(72)!,
        accumulation_duration: response?.data.readInt32LE(76)!,
        generation_duration: response?.data.readInt32LE(80)!
      };
    });


  }

  /**
   * Parses the data buffer of an NFT account to extract relevant information.
   *
   * @param acc {PublicKey} The public key of the NFT account to parse.
   * @returns {Promise<NftRecord>} A Promise that resolves to an object containing the parsed NFT data:
   *   - claimed_amount: The number of NFTs that have been claimed.
   *   - total_amount: The total number of NFTs available in the collection.
   *   - last_claimed_at: The timestamp (in Unix seconds) of the last claim.
   */
  async parseNft(acc: PublicKey): Promise<NftRecord> {
    return this.getAccountInfo(acc).then((resp) => {
      return {
        claimed_amount: resp.data.readInt32LE(0),
        total_amount: resp.data.readInt32LE(4),
        last_claimed_at: Number(resp.data.readInt32LE(8))
      };
    });
  }

}
