import { Injectable } from '@angular/core';
import { from, Observable, Subscriber } from 'rxjs';
import { Address, readContract, watchContractEvent, writeContract, WriteContractResult } from '@wagmi/core';
import { Log } from 'viem';
import { EVENT_TYPE } from './genesis.enums';
import claimAbi from './abis/claim.abi';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenesisClaimService {
  readonly SMART_CONTRACT_ADDRESS: Address = environment.genesis.smartContracts.claim as Address;

  watchContractEvent(eventName: EVENT_TYPE, walletAddress?: Address): Observable<Log[]> {
    return new Observable((subscriber: Subscriber<Log[]>) => {
      const configuration = {
        address: this.SMART_CONTRACT_ADDRESS,
        abi: claimAbi,
        eventName
      };

      const unwatch = watchContractEvent(configuration, (logs: Log[]) => {
        const senderWalletAddress = (logs[0] as any).args.userAddress;

        if (walletAddress == undefined || walletAddress === senderWalletAddress) {
          subscriber.next(logs);
        }
      });

      return () => {
        unwatch();
      };
    });
  }

  /** Read methods */

  /**
   * Get locked user nfts
   *
   * @param walletAddress Locked nfts owner wallet address
   *
   * @return {bigint[]} - Array of locked nft ids
   */
  getUserNftIds(walletAddress: Address): Observable<bigint[]> {
    const configuration = {
      address: this.SMART_CONTRACT_ADDRESS,
      abi: claimAbi,
      functionName: 'getUserNftIds',
      args: [walletAddress]
    };

    return from(readContract(configuration) as Promise<bigint[]>);
  }

  /** Write methods */

  /**
   * Claim user nfts
   *
   * @return {WriteContractResult} Result of claiming nfts
   */
  claim(): Observable<WriteContractResult> {
    const configuration = {
      address: this.SMART_CONTRACT_ADDRESS,
      abi: claimAbi,
      functionName: 'claim',
      args: []
    };

    return from(writeContract(configuration));
  }

  /**
   * Lock nfts
   *
   * @param nftIds Nft ids for lock
   *
   * @return {WriteContractResult} Result of locking nfts
   */
  lock(nftIds: number[]): Observable<WriteContractResult> {
    const configuration = {
      address: this.SMART_CONTRACT_ADDRESS,
      abi: claimAbi,
      functionName: 'lock',
      args: [nftIds]
    };

    return from(writeContract(configuration));
  }

  /**
   * Unlock nfts
   *
   * @param nftIds Nft ids for unlock
   *
   * @return {WriteContractResult} Result of unlocking nfts
   */
  unlock(nftIds: number[]): Observable<WriteContractResult> {
    const configuration = {
      address: this.SMART_CONTRACT_ADDRESS,
      abi: claimAbi,
      functionName: 'unlock',
      args: [nftIds]
    };

    return from(writeContract(configuration));
  }

  /**
   * Unlock all user nfts
   *
   * @return {WriteContractResult} Result of unlocking all nfts
   */
  unlockAll(): Observable<WriteContractResult> {
    const configuration = {
      address: this.SMART_CONTRACT_ADDRESS,
      abi: claimAbi,
      functionName: 'unlockAll',
      args: []
    };

    return from(writeContract(configuration));
  }
}
