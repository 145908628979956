import { Component, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  readonly currentYear: number = new Date().getFullYear();

  @Input() showNavigation: Boolean = true;

  constructor(public modalService: ModalService) {}
}
