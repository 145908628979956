import { Component } from '@angular/core';
import { WhitelistedService } from './shared/services/whitelisted.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-chat></app-chat>
  `,
  styles: [
    `
      :host {
      }
    `
  ]
})
export class AppComponent {
  constructor(private whitelistedService: WhitelistedService) {
  }
}
