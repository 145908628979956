// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gaimin__button {
  width: 100%;
  padding: 8px 20px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  transition: 0.4s;
}
.gaimin__button:hover, .gaimin__button[disabled] {
  opacity: 0.7;
}
.gaimin__button.dark {
  color: var(--color-highlight-text);
  background: transparent;
  border: 1px solid var(--color-highlight-text);
}
.gaimin__button.light {
  color: #000;
  background: var(--color-text);
  border: 1px solid var(--color-text);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/main-button/main-button.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAAE;EAEE,YAAA;AACJ;AACE;EACE,kCAAA;EACA,uBAAA;EACA,6CAAA;AACJ;AACE;EACE,WAAA;EACA,6BAAA;EACA,mCAAA;AACJ","sourcesContent":[".gaimin__button {\n  width: 100%;\n  padding: 8px 20px;\n  font-weight: bold;\n  text-transform: uppercase;\n  border-radius: 5px;\n  transition: 0.4s;\n  &:hover,\n  &[disabled] {\n    opacity: 0.7;\n  }\n  &.dark {\n    color: var(--color-highlight-text);\n    background: transparent;\n    border: 1px solid var(--color-highlight-text);\n  }\n  &.light {\n    color: #000;\n    background: var(--color-text);\n    border: 1px solid var(--color-text);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
