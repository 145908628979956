import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { WebSocketService } from '../../shared/services/web-socket.service';
import { SolanaService } from '../../shared/services/solana/solana.service';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-solana-wallet-login',
  templateUrl: './solana-wallet-login.component.html',
  styleUrls: ['./solana-wallet-login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SolanaWalletLoginComponent implements OnDestroy, OnInit {

  onMessageSubjectSub: Subscription | undefined;

  constructor(private webSocketService: WebSocketService,
              public solanaService: SolanaService,
              private clipboardService: ClipboardService,
              private toastrService: ToastrService
  ) {
    this.webSocketService.initWebSocket();
  }

  ngOnInit() {
    this.onMessageSubjectSub = this.webSocketService.onMessage().subscribe(async (message) => {
      if (message.includes('connect')) {
        await this.walletLogin(false);
      }
      if (message.includes('manage')) {
        await this.walletLogin(true);
      }
    });
  }

  async walletLogin(manageWallet?: boolean) {
    try {
      await this.solanaService.initSocketConnection(manageWallet);
    } catch (error) {
      console.error('Error during wallet login:', error);
    }
  }

  onCopy() {
    const walletAddress = this.solanaService.connectedWalletAddress$.getValue();
    this.clipboardService.copy(walletAddress);
    this.toastrService.success(`The address "${walletAddress}" copied.`);

  }

  ngOnDestroy() {
    this.webSocketService.sendMessage('close');
    this.onMessageSubjectSub?.unsubscribe();
  }


}
