import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  customBnbAddressValidator(control: FormControl): ValidationErrors | null {
    const walletAddress = control.value;
    const errorMessage = 'BNB wallet address is invalid!';
    const bscAddressPattern = /^0x[a-fA-F0-9]{40}$/;
    const isValidAddress = bscAddressPattern.test(walletAddress);

    return isValidAddress ? null : { incorrect: true, errorMessage };
  }

  setValueInSessionStorage(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  getValueFromSessionStorage(key: string): string | null {
    if (sessionStorage.getItem(key)) {
      return sessionStorage.getItem(key);
    } else {
      console.log(`${key} is undefined`);
      return null;
    }
  }

  removeValueFromSessionStorage(key: string) {
    sessionStorage.removeItem(key);
  }

}
