import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Address, readContract, writeContract, WriteContractResult } from '@wagmi/core';
import erc721Abi from './abis/erc721.abi';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenesisErc721Service {
  readonly SMART_CONTRACT_ADDRESS: Address = environment.genesis.smartContracts.erc721 as Address;

  getUnlockedNftIds(walletAddress: Address): Observable<bigint[]> {
    return this.balanceOf(walletAddress).pipe(
      switchMap((countUnlockedNfts: bigint) => {
        const result: Observable<bigint>[] = [];

        for (let index: number = 0; index < countUnlockedNfts; index++) {
          result.push(this.tokenOfOwnerByIndex(walletAddress, index));
        }

        if (result.length === 0) {
          return of([]);
        }

        return forkJoin(result);
      })
    );
  }

  setApprovalForAll(
    approved: boolean,
    walletAddress: Address,
    operator: Address
  ): Observable<WriteContractResult | undefined> {
    return this.isApprovedForAll(walletAddress, operator).pipe(
      switchMap((isApproved: boolean) => {
        if (isApproved === approved) {
          return of(undefined);
        }

        const configuration = {
          address: this.SMART_CONTRACT_ADDRESS,
          abi: erc721Abi,
          functionName: 'setApprovalForAll',
          args: [operator, approved]
        };

        return from(writeContract(configuration));
      })
    );
  }

  isApprovedForAll(walletAddress: Address, operator: Address): Observable<boolean> {
    const configuration = {
      address: this.SMART_CONTRACT_ADDRESS,
      abi: erc721Abi,
      functionName: 'isApprovedForAll',
      args: [walletAddress, operator]
    };

    return from(readContract(configuration) as Promise<boolean>);
  }

  private balanceOf(walletAddress: Address): Observable<bigint> {
    const configuration = {
      address: this.SMART_CONTRACT_ADDRESS,
      abi: erc721Abi,
      functionName: 'balanceOf',
      args: [walletAddress]
    };

    return from(readContract(configuration) as Promise<bigint>);
  }

  private tokenOfOwnerByIndex(walletAddress: Address, index: number): Observable<bigint> {
    const configuration = {
      address: this.SMART_CONTRACT_ADDRESS,
      abi: erc721Abi,
      functionName: 'tokenOfOwnerByIndex',
      args: [walletAddress, index]
    };

    return from(readContract(configuration) as Promise<bigint>);
  }
}
