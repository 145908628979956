import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WriteContractResult } from '@wagmi/core';
import { ModalService } from '../../services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { GenesisService } from '../../services/genesis/genesis.service';

@Component({
  selector: 'app-approval-claim-modal',
  templateUrl: './approval-claim-modal.component.html',
  styleUrls: ['./approval-claim-modal.component.scss']
})
export class ApprovalClaimModalComponent {
  isInProgress: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: ModalService,
    private toastrService: ToastrService,
    public genesisService: GenesisService
  ) {}

  claimAllNFTs() {
    this.isInProgress = true;

    this.genesisService.claimNfts().subscribe({
      next: (claimData: WriteContractResult) => {
        console.log('claimNft data:', claimData);
        this.closeModal();
        this.modalService.openSuccessClaimModal(claimData.hash);
      },
      error: (err) => {
        console.error(err);
        this.toastrService.error(err.message);
        this.isInProgress = false;
      },
      complete: () => {
        this.isInProgress = false;
      }
    });
  }

  closeModal() {
    this.activeModal.close();
  }
}
