// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty {
  padding-top: 30px;
}
.empty-flexbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 14px;
}
.empty-flexbox__image {
  width: 29px;
  height: 29px;
  margin: 0 auto;
}
.empty-flexbox__text-title {
  font-size: 18px;
  line-height: 25px;
  color: var(--color-text);
  padding-bottom: 5px;
}
.empty-flexbox__text-subtitle {
  font-size: 15px;
  line-height: 25px;
  color: var(--color-text);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/empty/empty.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,SAAA;AAEJ;AAAI;EACE,WAAA;EACA,YAAA;EACA,cAAA;AAEN;AAEM;EACE,eAAA;EACA,iBAAA;EACA,wBAAA;EACA,mBAAA;AAAR;AAGM;EACE,eAAA;EACA,iBAAA;EACA,wBAAA;AADR","sourcesContent":[".empty {\n  padding-top: 30px;\n  &-flexbox {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    text-align: center;\n    gap: 14px;\n\n    &__image {\n      width: 29px;\n      height: 29px;\n      margin: 0 auto;\n    }\n\n    &__text {\n      &-title {\n        font-size: 18px;\n        line-height: 25px;\n        color: var(--color-text);\n        padding-bottom: 5px;\n      }\n\n      &-subtitle {\n        font-size: 15px;\n        line-height: 25px;\n        color: var(--color-text);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
