// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-to-top {
  display: none;
  position: fixed;
  bottom: 20%;
  right: 2%;
  transition: all 0.2s ease-in-out;
  z-index: 1;
}
.scroll-to-top__show {
  display: block;
  transition: all 0.2s ease-in-out;
}
.scroll-to-top__button {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: #000;
  color: var(--color-text);
  border: none;
  transition: 0.5s;
}
.scroll-to-top__button:hover {
  color: var(--color-highlight-text);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/scroll-top/scroll-top.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,WAAA;EACA,SAAA;EACA,gCAAA;EACA,UAAA;AACF;AACE;EACE,cAAA;EACA,gCAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,wBAAA;EACA,YAAA;EACA,gBAAA;AAAJ;AAEI;EACE,kCAAA;AAAN","sourcesContent":[".scroll-to-top {\n  display: none;\n  position: fixed;\n  bottom: 20%;\n  right: 2%;\n  transition: all .2s ease-in-out;\n  z-index: 1;\n\n  &__show {\n    display: block;\n    transition: all .2s ease-in-out;\n  }\n\n  &__button {\n    width: 35px;\n    height: 35px;\n    border-radius: 5px;\n    background: #000;\n    color: var(--color-text);\n    border: none;\n    transition: 0.5s;\n\n    &:hover {\n      color: var(--color-highlight-text);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
