import { Component, OnInit, OnDestroy } from '@angular/core';
import { SolanaService } from '../../shared/services/solana/solana.service';
import { WebSocketService } from '../../shared/services/web-socket.service';
import { Subscription } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { SolanaClaimService } from '../../shared/services/solana/solana-claim.service';

@Component({
  selector: 'app-solana-claim-nft',
  templateUrl: './solana-claim-nft.component.html',
  styleUrls: ['./solana-claim-nft.component.scss'],
  animations: [
    trigger('smoothInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.9)' }),
        animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0, transform: 'scale(0.9)' }))
      ])
    ])
  ]

})
export class SolanaClaimNftComponent implements OnInit, OnDestroy {

  private onMessageSubjectSub: Subscription | undefined;

  constructor(public solanaService: SolanaService,
              public claimService: SolanaClaimService,
              private webSocketService: WebSocketService,
              private toastrService: ToastrService) {
    this.webSocketService.initWebSocket();
  }

  async ngOnInit(): Promise<void> {
    this.onMessageSubjectSub = this.webSocketService.onMessage().subscribe(async (message) => {
      switch (true) {
        case message.includes('claimRewardInfo'):
          await this.claimStake(message);
          break;
        case message.includes('hold'):
          await this.lockNft(message);
          break;
        case message.includes('un-lock'):
          await this.unlockNft(message);
          break;
        default:
          break;
      }
    });
  }

  async claimStake(message: string) {
    const extractClaimRewardInfo = this.claimService.extractMessageFromWebSocket(message);

    if (extractClaimRewardInfo) {
      await this.claimService.claimRewards(extractClaimRewardInfo.walletAddress, extractClaimRewardInfo.nftMintAddresses);
    } else {
      this.toastrService.error('Something went wrong, please try again later', 'Claim info error');
    }
  }

  async lockNft(message: string) {
    const extractLockNftInfo = this.claimService.extractMessageFromWebSocket(message);
    await this.claimService.delegateAndStake(extractLockNftInfo.nftMintAddresses);
  }

  async unlockNft(message: string) {
    const extractUnLockNftInfo = this.claimService.extractMessageFromWebSocket(message);
    await this.claimService.revokeAndUnstake(extractUnLockNftInfo.nftMintAddresses);
  }

  ngOnDestroy() {
    this.webSocketService.sendMessage('close');
    this.onMessageSubjectSub?.unsubscribe();
  }

}
