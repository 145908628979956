import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output
} from '@angular/core';
import { TurnstileService } from '../../../shared/services/turnstile.service';

@Component({
  selector: 'app-captcha',
  template: '<div id="turnstile-captcha"></div>'
})
export class CaptchaComponent implements AfterViewInit, OnDestroy {
  @Output() token = new EventEmitter<string>();
  @Output() expired = new EventEmitter<void>();
  @Output() error = new EventEmitter<void>();
  private refreshInterval: any;
  REFRESH_TOKEN_TIMEOUT = 30000;

  constructor(
    private turnstileService: TurnstileService,
    private elRef: ElementRef
  ) {
  }

  ngAfterViewInit() {
    this.turnstileService.initTurnstileCaptcha(this.elRef, this.token, this.expired, this.error);

    this.refreshInterval = setInterval(() => {
      this.turnstileService.refreshToken().catch((error) => {
        console.error('Error refreshing Turnstile token:', error);
      });
    }, this.REFRESH_TOKEN_TIMEOUT);


  }

  ngOnDestroy(): void {
    this.turnstileService.destroyCaptcha();
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }


}


