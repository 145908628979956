import { Injectable } from '@angular/core';
import { WebSocketService } from '../web-socket.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction
} from '@solana/web3.js';
import { SolanaProvider, TransactionInfo } from './tools/solana.interfaces';


@Injectable({
  providedIn: 'root'
})
export class SolanaService {
  customWindow = (window as any);
  connectButtonVisible: boolean = false;
  changeWalletButtonVisible: boolean = false;
  downloadButtonVisible: boolean = false;
  downloadPhantomUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(environment.downloadPhantomChrome);
  isResendButtonShown: boolean = false;
  infoConnectMessage: string = 'Close your browser after Phantom is connected.';
  infoMessageTransaction: string = 'Close your browser after transaction  is confirmed.';
  infoMessageLoading: string = 'Please wait while your operation is in progress.';
  infoClaimMessage: string = 'Close your browser after claim  is confirmed.';
  transactionInfo$: BehaviorSubject<TransactionInfo> = new BehaviorSubject<TransactionInfo>({
    walletAddress: '',
    amount: ''
  });
  connectedWalletAddress$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private previousWalletAddress: string = '';
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private delay = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  constructor(
    private webSocketService: WebSocketService,
    private toastrService: ToastrService) {
    this.detectBrowser();
  }

  /**
   * Establishes a WebSocket connection, updates UI elements based on the connected wallet and manages wallet functionality if specified.
   *
   * @param {boolean} [manageWallet=false] - Whether to enable UI elements for managing the connected wallet.
   *
   * @throws {Error} - Rejects the promise if there's an error during connection initialization.
   *
   * @return {Promise<void>} - Promise that resolves upon successful connection.
   */
  async initSocketConnection(manageWallet?: boolean): Promise<void> {
    try {
      const publicKey = await this.getUserPublicKey();
      this.previousWalletAddress = this.connectedWalletAddress$.getValue();
      this.webSocketService.sendMessage('public key: ' + publicKey.toString());


      this.infoConnectMessage = 'Phantom is connected. You can close the browser';
      this.connectButtonVisible = false;
      this.connectedWalletAddress$.next(publicKey.toString());


      if (manageWallet) {
        this.infoConnectMessage = 'Switch your account in the Phantom extensions and click the \'Change Wallet\' button for an easy update ';
        this.changeWalletButtonVisible = true;
        if (this.previousWalletAddress !== publicKey.toString() && this.previousWalletAddress.length > 1) {
          this.toastrService.success(`Wallet has changed to ${publicKey}`);
        } else if (this.previousWalletAddress === publicKey.toString()) {
          this.toastrService.success(`The wallet hasn't changed, you're still using it.`);
        }
      } else {
        if (publicKey) {
          this.addSuccessQueryParam();
          this.toastrService.success('Phantom wallet is connected successfully');
        }
        this.changeWalletButtonVisible = false;
      }


    } catch (connectionError) {
      await this.handleConnectionError(connectionError);
    }
  }

  /**
   * Handles errors that occur during socket connection initialization and updates UI elements accordingly.
   *
   * @param {Error} error - The error object received during the connection attempt.
   *
   * @return {Promise<void>} - Promise that resolves upon successful handling of the error.
   */
  private async handleConnectionError(error: any): Promise<void> {
    try {
      this.connectButtonVisible = true;
      console.error('Error during socket connection initialization:', error);
      this.toastrService.error('Something went wrong. Verify your Phantom wallet in the browser and retry later.');
      if (!this.customWindow?.solana) {
        this.downloadButtonVisible = true;
        this.connectButtonVisible = false;
        this.infoConnectMessage = 'Please install Phantom Wallet and try again.';
      }
    } catch (error) {
      console.error('Unexpected error during connection error handling:', error);
    }
  }

  /**
   * Updates the current URL by adding a 'status' query parameter with the value 'SUCCESS'.
   */
  private addSuccessQueryParam(): void {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('status', 'SUCCESS');
    const modifiedUrl = currentUrl.split('?')[0] + '?' + urlParams.toString();
    history.pushState({ path: modifiedUrl }, '', modifiedUrl);
  }

  /**
   * Initiates a Solana coin transfer to the specified wallet address with the given amount.
   *
   * @param {string} walletAddress - The recipient's wallet address.
   * @param {string} amount - The amount of SOL to transfer.
   *
   * @throws {Error} - Rejects the promise if there's an error during the transfer process.
   *
   * @return {Promise<void>} - Promise that resolves upon successful transfer.
   */
  async coinTransfer(walletAddress: string, amount: string): Promise<void> {
    this.customWindow.solana?.connect()
    .then(async (provider: any) => {
      try {
        this.isLoading.next(true);
        const connection = new Connection(`${environment.solana.solanaRPC}`);
        const key = provider?.publicKey?.toString();
        const fromAccount = new PublicKey(key);
        const balance = await connection.getBalance(fromAccount);
        const recentBlockhash = await connection.getLatestBlockhash('finalized');
        const recipientPublicKey = new PublicKey(walletAddress);
        if (balance > Number(amount)) {
          const transaction = new Transaction().add(
            SystemProgram.transfer({
              fromPubkey: fromAccount,
              toPubkey: recipientPublicKey,
              lamports: Number(amount) * LAMPORTS_PER_SOL
            })
          );

          transaction.feePayer = fromAccount;
          transaction.recentBlockhash = recentBlockhash.blockhash;

          const signedTransaction = await this.customWindow.phantom?.solana.signTransaction(transaction);
          await connection.sendRawTransaction(signedTransaction.serialize())
          .then((signature) => {
            this.webSocketService.sendMessage('Your transaction is confirmed: ' + signature);
            console.log('Transaction successful. Signature:', signature);
          }).finally(() => {
            this.isLoading.next(false);
            this.infoMessageTransaction = 'Your transaction is confirmed. You may now close your browser.';
          });

        } else {
          this.webSocketService.sendMessage('Error: Insufficient funds. Your balance is not enough for the transaction. Please check your account balance and try again.');
          this.infoMessageTransaction = ' Your balance is not enough for the transaction.';
          this.isLoading.next(false);
          return console.error('Error transaction. Your balance is invalid');
        }

      } catch (error: any) {
        console.error('Error transaction', error);
        if (error?.message.includes('rejected')) {
          this.infoMessageTransaction = error?.message;
          this.webSocketService.sendMessage('Error: Transfer is rejected');
          this.isResendButtonShown = true;
          this.isLoading.next(false);
        } else {
          this.infoMessageTransaction = 'Something went wrong. Please try again later.';
          this.webSocketService.sendMessage('Error: Something went wrong. Please try again later.');
        }

      }
    });

  }

  /**
   * Extracts transaction information from a given message string.
   *
   * @param {string} message - The message string containing transaction information.
   *
   * @return {TransactionInfo} - An object containing extracted wallet address and amount.
   */
  extractTransactionInfo(message: string): TransactionInfo {
    const walletAddressMatch = RegExp(/walletAddress:\s*(\w+)/).exec(message);
    const amountMatch = RegExp(/amount:\s*(\d+(\.\d+)?)/).exec(message);

    return {
      walletAddress: walletAddressMatch ? walletAddressMatch[1] : '',
      amount: amountMatch ? amountMatch[1] : ''
    };

  }

  /**
   * Detects the user's browser and triggers a download event for the Phantom wallet extension if Firefox is detected.
   *
   * @param {void} - This method does not accept any parameters.
   *
   * @return {void} - This method does not return any value.
   */
  detectBrowser(): void {
    const userAgent = window.navigator.userAgent;
    if (userAgent.includes('Firefox')) {
      this.downloadPhantomUrl$.next(environment.downloadPhantomFireFox);
    }
  }

  /**
   * Attempts to get the Solana provider object from the customWindow object, if available.
   *
   * @param {void} - This method does not accept any parameters.
   *
   * @return {SolanaProvider | null} - Returns the Solana provider object if found and valid (isPhantom property is true), otherwise returns null.
   */
  qetProvider() {
    if ('solana' in this.customWindow) {
      const provider = this.customWindow?.solana;
      if (provider?.isPhantom) {
        return provider;
      }
    }
  };


  /**
   * Gets a valid Solana provider object with retries in case the provider is not immediately available.
   *
   * @param {number} [maxRetries=3] - The maximum number of retries to attempt getting the provider (defaults to 3).
   * @param {number} [delayBetweenRetries=1000] - The delay (in milliseconds) between retries (defaults to 1000).
   *
   * @return {Promise<SolanaProvider | null>} - Promise that resolves to the Solana provider object if found and valid, or null if not found after retries.
   */
  async qetProviderWithRetry(maxRetries: number = 3, delayBetweenRetries: number = 1000): Promise<SolanaProvider | null> {
    let retries = 0;
    let provider;
    while (retries < maxRetries) {
      provider = this.qetProvider();
      if (provider?.isPhantom) {
        return provider;
      }
      retries++;
      await this.delay(delayBetweenRetries);
    }
    console.error(`Failed to get a valid provider after ${maxRetries} retries.`);
    return null;
  }

  /**
   * Gets the user's public key using the connected Solana provider.
   *
   * @throws {Error} - If there are errors connecting to or retrieving the public key from the provider.
   *
   * @return {Promise<PublicKey>} - Promise that resolves to the user's public key if successful, or rejects with an error.
   */
  async getUserPublicKey(): Promise<PublicKey | any> {
    const provider = await this.qetProviderWithRetry();

    try {
      if (provider) {
        await provider.connect();
        return provider.publicKey;
      }
    } catch (error) {
      console.error('Error on getting user wallet', error);
    }
  }

}
