import { Component, OnDestroy, OnInit } from '@angular/core';
import { SolanaService } from '../../shared/services/solana/solana.service';
import { WebSocketService } from '../../shared/services/web-socket.service';
import { TransactionInfo } from '../../shared/services/solana/tools/solana.interfaces';
import { Subscription } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-solana-transaction',
  templateUrl: './solana-transaction.component.html',
  styleUrls: ['./solana-transaction.component.scss'],
  animations: [
    trigger('smoothInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.9)' }),
        animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({
          opacity: 1,
          transform: 'scale(1)'
        }))
      ]),
      transition(':leave', [
        animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({
          opacity: 0,
          transform: 'scale(0.9)'
        }))
      ])
    ])
  ]

})
export class SolanaTransactionComponent implements OnInit, OnDestroy {

  transactionInfo: TransactionInfo = { walletAddress: '', amount: '0' };
  private onMessageSubjectSub: Subscription | undefined;


  constructor(public solanaService: SolanaService, private webSocketService: WebSocketService) {
    this.webSocketService.initWebSocket();
  }

  ngOnInit(): void {
    this.solanaService.transactionInfo$.subscribe((transactionInfo) => {
      this.transactionInfo = transactionInfo;
    });

    this.onMessageSubjectSub = this.webSocketService.onMessage().subscribe((message) => {
      if (message.includes('transactionInfo')) {
        this.sendTransaction(message);
      }
    });

  }

  sendTransaction(message: string) {
    const parsedTransactionInfo = this.solanaService.extractTransactionInfo(message);
    if (parsedTransactionInfo) {
      this.solanaService.transactionInfo$.next(parsedTransactionInfo);
      this.solanaService.coinTransfer(parsedTransactionInfo.walletAddress, parsedTransactionInfo.amount)
      .catch(err => {
        console.log('Error transaction', err);
      });
    } else {
      console.error('Unable to parse wallet address and amount from the message.');
      this.solanaService.infoMessageTransaction = 'Something went wrong please try again';
    }
  }

  resendTransaction() {
    this.solanaService.coinTransfer(this.transactionInfo.walletAddress, this.transactionInfo.amount)
    .then(() => {
      this.solanaService.isResendButtonShown = false;
      this.solanaService.infoMessageTransaction = 'Close your browser after transaction  is confirmed.';
    })
    .catch(err => {
      console.log('Error transaction', err);
      this.solanaService.infoMessageTransaction = 'Something went wrong please try again';
    });
  }

  ngOnDestroy(): void {
    this.webSocketService.sendMessage('close');
    this.onMessageSubjectSub?.unsubscribe();
  }

}
