import { Component, Input } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-solana-content',
  templateUrl: './solana-content.component.html',
  styleUrls: ['./solana-content.component.scss'],
  animations: [
    trigger('imageSmoothInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.9)' }),
        animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0, transform: 'scale(0.9)' }))
      ])
    ])
  ]

})
export class SolanaContentComponent {
  @Input() isLoading: boolean = false;
}
