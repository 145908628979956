import { Component, OnDestroy, OnInit } from '@angular/core';
import { SolanaPfpService } from '../../shared/services/solana/solana-pfp.service';
import { SolanaService } from '../../shared/services/solana/solana.service';
import { WalletAdapterService } from '../../shared/services/wallet-adapter.service';
import { PhantomDeeplinksService } from '../../shared/services/phantom-deeplinks.service';
import { Observable, Subscription } from 'rxjs';
import { SolanaClaimService } from '../../shared/services/solana/solana-claim.service';
import { NftData, StateOrder } from '../../shared/services/solana/tools/solana.interfaces';
import { ModalService } from '../../shared/services/modal.service';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from '../../shared/services/utils.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { TOKEN_RECORD_STATE } from '../../shared/services/solana/tools/solana.enums';

@Component({
  selector: 'app-solana-pfp-nft',
  templateUrl: './solana-pfp-nft.component.html',
  styleUrls: ['./solana-pfp-nft.component.scss']
})
export class SolanaPfpNftComponent implements OnInit, OnDestroy {
  private adressSub: Subscription | undefined;
  private userBalanceSub: Subscription | undefined;
  private nftsDataSub: Subscription | undefined;
  protected nftsData: NftData[] | undefined;
  protected walletAddress: string = '';
  protected userBalance: number = 0;
  protected nftsStake: NftData[] | undefined;
  protected nftsUnstake: NftData[] | undefined;
  protected claimAllNfts: NftData[] | undefined;
  protected selectedNftsClaim: NftData[] | undefined;
  protected selectedNftsStake: NftData[] | undefined;
  protected selectedNftsUnstake: NftData[] | undefined;
  private stateOrder: StateOrder = { Locked: 1, Unlocked: 2 };
  isMobile$: Observable<boolean> | undefined;
  selectedLimit: number = 5;

  constructor(
    public solanaPfpService: SolanaPfpService,
    public solanaService: SolanaService,
    private utilsService: UtilsService,
    public toastrService: ToastrService,
    public solanaClaimService: SolanaClaimService,
    public phantomService: PhantomDeeplinksService,
    private clipboardService: ClipboardService,
    public modalService: ModalService,
    public walletAdapterService: WalletAdapterService,
    private breakpointObserver: BreakpointObserver
  ) {
  }

  ngOnInit(): void {

    this.isMobile$ = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

    this.adressSub = this.walletAdapterService.address$.subscribe(async (userPublicKey) => {
      this.walletAddress = userPublicKey;
      if (userPublicKey) {
        await this.solanaPfpService.getUserNFTs(userPublicKey);
      } else {
        this.nftsData = [];
      }
    });

    this.nftsDataSub = this.solanaPfpService.solanaNftsData$.subscribe((nfts) => {
      this.nftsData = nfts;
      [this.nftsStake, this.nftsUnstake] = Object.values(TOKEN_RECORD_STATE).map((state) =>
        nfts.filter((nft) => nft?.state === state)
      );
      this.claimAllNfts = this.nftsUnstake.filter(
        (nft) => nft?.nftRecord?.claimed_amount !== nft?.nftRecord?.total_amount
      );

      this.selectedNftsStake = this.nftsUnstake.filter(nft => nft.selected === true);
      this.selectedNftsUnstake = this.nftsStake.filter(nft => nft.selected === true);
      this.selectedNftsClaim = this.selectedNftsStake.filter(
        (nft) => nft?.nftRecord?.claimed_amount !== nft?.nftRecord?.total_amount && nft.currentReward > 0
      );
      this.solanaPfpService.isNftLoading = false;
      this.nftsData.sort((a, b) => {
        return this.stateOrder[a.state] - this.stateOrder[b.state];
      });
    });

    if (this.solanaService.customWindow.solana) {
      let publicKeyFromSessionStorage = this.utilsService.getValueFromSessionStorage('userPublicKey');
      this.walletAdapterService.addressSubject.next(publicKeyFromSessionStorage);
      if (publicKeyFromSessionStorage) {
        this.walletAddress = publicKeyFromSessionStorage;
        this.walletAdapterService.walletConnectTitle = 'Change Wallet';
        this.solanaPfpService.getWalletBalance(publicKeyFromSessionStorage).then(async () => {
          await this.solanaPfpService.getUserNFTs(publicKeyFromSessionStorage!);
        });
      } else {
      }
    }

    this.userBalanceSub = this.solanaPfpService.solanaBalance$.subscribe((balance) => {
      this.userBalance = balance;
    });
  }

  walletLogin() {
    try {
      this.walletAdapterService.connect();
    } catch (error) {
      console.error('Error during wallet login:', error);
    }
  }

  async walletLoginDesktop() {
    try {
      await this.walletAdapterService.desktopConnect();
    } catch (error) {
      console.error('Error during wallet login:', error);
    }
  }

  claimAll() {
    if (!this.claimAllNfts) return;
    const mintAddresses = this.claimAllNfts.map((nft) => nft.id);
    this.solanaClaimService.claimAllMintAddresses$.next(mintAddresses);
    this.modalService.openClaimModal();

  }

  claimSelected() {
    if (!this.selectedNftsClaim) return;
    const mintAddresses = this.selectedNftsClaim.map((nft) => nft.id);
    this.solanaClaimService.claimAllMintAddresses$.next(mintAddresses);
    this.modalService.openClaimModal();
  }

  async stakeAll() {
    if (!this.nftsStake) return;
    const mintAddresses = this.nftsStake.map((nft) => nft.id);
    await this.solanaClaimService.delegateAndStake(mintAddresses, this.walletAddress, true);

  }

  async unStakeAll() {
    if (this.nftsUnstake) {
      const mintAddresses = this.nftsUnstake.map((nft) => nft.id);
      await this.solanaClaimService.revokeAndUnstake(mintAddresses, this.walletAddress, true);
    }
  }

  async stakeSelected() {
    if (!this.selectedNftsUnstake) return;
    const mintAddresses = this.selectedNftsUnstake.map(nft => nft.id);
    await this.solanaClaimService.delegateAndStake(mintAddresses, this.walletAddress, true);
  }

  async unStakeSelected() {
    if (!this.selectedNftsStake) return;
    const mintAddresses = this.selectedNftsStake.map(nft => nft.id);
    await this.solanaClaimService.revokeAndUnstake(mintAddresses, this.walletAddress, true);
  }


  async refreshData() {
    await this.solanaPfpService.refreshData(this.walletAddress);
  }

  onCopy(walletAddress: string) {
    this.clipboardService.copy(walletAddress);
    this.toastrService.success(`The address "${walletAddress}" copied.`);
  }

  async onLimitChange() {
    this.solanaPfpService.limitPerPage = Number(this.selectedLimit);
    if (this.selectedLimit > 10) {
      this.solanaPfpService.currentPage = 1;
    }
    await this.solanaPfpService.getUserNFTs(this.walletAddress);
  }

  ngOnDestroy(): void {
    this.adressSub?.unsubscribe();
    this.nftsDataSub?.unsubscribe();
    this.userBalanceSub?.unsubscribe();
    this.nftsData = [];
    this.nftsStake = [];
    this.claimAllNfts = [];
    this.nftsUnstake = [];
    this.selectedNftsClaim = [];
    this.selectedNftsStake = [];
    this.selectedNftsUnstake = [];
  }

  async onPreviousPage() {
    this.solanaPfpService.currentPage > 1 ? this.solanaPfpService.currentPage = this.solanaPfpService.currentPage - 1 : this.solanaPfpService.currentPage = 1;
    await this.solanaPfpService.getUserNFTs(this.walletAddress);
  }

  async onNextPage() {
    this.solanaPfpService.currentPage = this.solanaPfpService.currentPage + 1;
    await this.solanaPfpService.getUserNFTs(this.walletAddress);
  }
}
