import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Response, Withdrawal } from '../../shared/interfaces';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-withdrawal-confirmation',
  templateUrl: './withdrawal-confirmation.component.html',
  styleUrls: ['./withdrawal-confirmation.component.scss']
})
export class WithdrawalConfirmationComponent implements OnInit {
  withdrawal: Withdrawal | undefined;
  exceptionMessage: string = '';
  year = new Date().getFullYear();

  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getUserWithdrawal();
  }

  getUserWithdrawal() {
    this.route.queryParamMap.subscribe((queryParams) => {
      const token = queryParams.get('withdrawalToken');
      this.http
        .get<Response<Withdrawal>>(environment.gaiminApi + '/withdrawal-details', {
          params: {
            verificationToken: token ? token : ''
          }
        })
        .subscribe((response) => {
          if (response.success) {
            this.withdrawal = response.data;
          } else {
            this.exceptionMessage = response.error?.description ? response.error.description : 'Something went wrong';
          }
        });
    });
  }
}
