// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-solana-content .main__content-image {
  display: none;
}
app-solana-content .main__content-text-title {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
}
app-solana-content .main__content-button {
  max-width: 100%;
  display: inline-block;
  margin-top: 20px;
  color: #fff;
  text-align: left;
  letter-spacing: 0;
  text-transform: capitalize;
  background-color: #776bf8;
  border-radius: 90px;
  font-weight: bold;
  padding: 12px 16px;
  transition-duration: 0.5s;
  text-decoration: none;
  cursor: pointer;
}
app-solana-content .main__content-button:hover {
  background-color: #5047b4;
}

.load-7 .square {
  animation: loadingG 2.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

.square {
  width: 46px;
  height: 50px;
  border-radius: 4px;
  background: no-repeat url("https://storage.googleapis.com/gaimin-images/ghost.svg");
}

@keyframes loadingG {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(250px, 0) rotate(360deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
.square-holder {
  padding-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/solana-transaction/solana-transaction.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAMQ;EACE,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAJV;AAQM;EACE,eAAA;EACA,qBAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,0BAAA;EACA,yBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,qBAAA;EACA,eAAA;AANR;AAQQ;EACE,yBAAA;AANV;;AAaA;EACE,sEAAA;AAVF;;AAaA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mFAAA;AAVF;;AAaA;EACE;IACE,uCAAA;EAVF;EAYA;IACE,6CAAA;EAVF;EAYA;IACE,uCAAA;EAVF;AACF;AAaA;EACE,oBAAA;AAXF","sourcesContent":["app-solana-content {\n  .main__content-image {\n    display: none;\n  }\n\n  .main {\n    &__content {\n      &-text {\n        &-title {\n          font-family: 'Open Sans', sans-serif;\n          font-weight: 400;\n          font-size: 20px;\n          line-height: 27px;\n          color: #ffffff;\n        }\n      }\n\n      &-button {\n        max-width: 100%;\n        display: inline-block;\n        margin-top: 20px;\n        color: #fff;\n        text-align: left;\n        letter-spacing: 0;\n        text-transform: capitalize;\n        background-color: #776bf8;\n        border-radius: 90px;\n        font-weight: bold;\n        padding: 12px 16px;\n        transition-duration: 0.5s;\n        text-decoration: none;\n        cursor: pointer;\n\n        &:hover {\n          background-color: #5047b4;\n        }\n      }\n    }\n  }\n}\n\n.load-7 .square {\n  animation: loadingG 2.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;\n}\n\n.square {\n  width: 46px;\n  height: 50px;\n  border-radius: 4px;\n  background: no-repeat url('https://storage.googleapis.com/gaimin-images/ghost.svg');\n}\n\n@keyframes loadingG {\n  0% {\n    transform: translate(0, 0) rotate(0deg);\n  }\n  50% {\n    transform: translate(250px, 0) rotate(360deg);\n  }\n  100% {\n    transform: translate(0, 0) rotate(0deg);\n  }\n}\n\n.square-holder {\n  padding-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
