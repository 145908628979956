export enum TOKEN_RECORD_STATE {
  Unlocked,
  Locked,
  Listed
}

export enum DelegateRole {
  Sale,
  Transfer,
  Utility,
  Staking,
  Standard,
  LockedTransfer,
  Migration = 255,
}

export enum SOLANA_CREATORS_ADDRESS {
  'AoG8djqzoVinFggzhGDGb2zu6iTHz4h8MXUnnEqxVZTC',
  'Aorbs5ot6Jp47T1BnhDpgFkutd11FpU8qpkZN5FhsB8a',
  '6mycahc3yUn3DwdLUBi3NspCZTSBg3JUgBGSBFbwGhiT',
  '6283kwQGeCA5C6sxNn4TWtVq3GKrZ22CGSZEtaw8YNkk',
  'DsT44zjuRc7AsxnNB8Fr8UZueJHtX7V4iKeF6f69e5Xo'
}
