import { Injectable } from '@angular/core';
import { PublicKey } from '@solana/web3.js';
import { SolanaConstantsService } from './tools/solana-constants.service';


@Injectable({
  providedIn: 'root'
})
export class SolanaPdaService {

  constructor(private solanaConstants: SolanaConstantsService) {
  }

  /**
   * Generates the Program Derived Address (PDA) for a token account associated with a specific wallet and mint.
   *
   * @param mint {PublicKey} The public key of the mint associated with the token account.
   * @param wallet {PublicKey} The public key of the wallet that owns the token account.
   * @returns {[PublicKey, number]} A tuple containing the derived public key and the number of seeds used.
   */
  getTokenAccountPda(mint: PublicKey, wallet: PublicKey): [PublicKey, number] {
    return PublicKey.findProgramAddressSync(
      [
        wallet.toBuffer(),
        this.solanaConstants.TOKEN_PROGRAM_ID.toBuffer(),
        mint.toBuffer()
      ],
      this.solanaConstants.SPL_ASSOCIATED_TOKEN_ACCOUNT
    );
  }

  /**
   * Generates the Program Derived Address (PDA) for the metadata account associated with a specific mint.
   *
   * @param mint {PublicKey} The public key of the mint for which to retrieve the metadata account PDA.
   * @returns {[PublicKey, number]} A tuple containing the derived public key and the number of seeds used.
   */
  getMetadataAccountPda(mint: PublicKey): [PublicKey, number] {
    return PublicKey.findProgramAddressSync(
      [
        Buffer.from('metadata'),
        this.solanaConstants.MPL_TOKEN_METADATA.toBuffer(),
        mint.toBuffer()
      ],
      this.solanaConstants.MPL_TOKEN_METADATA
    );
  }

  /**
   * Generates the Program Derived Address (PDA) for the master edition account associated with a specific mint.
   *
   * @param mint {PublicKey} The public key of the mint for which to retrieve the master edition account PDA.
   * @returns {[PublicKey, number]} A tuple containing the derived public key and the number of seeds used.
   */
  getMasterEditionAccountPda(mint: PublicKey): [PublicKey, number] {
    return PublicKey.findProgramAddressSync(
      [
        Buffer.from('metadata'),
        this.solanaConstants.MPL_TOKEN_METADATA.toBuffer(),
        mint.toBuffer(),
        Buffer.from('edition')
      ],
      this.solanaConstants.MPL_TOKEN_METADATA
    );
  }

  /**
   * Generates the Program Derived Address (PDA) for the token record account associated with a specific mint and token.
   *
   * @param mint {PublicKey} The public key of the mint associated with the token record.
   * @param token {PublicKey} The public key of the specific token.
   * @returns {[PublicKey, number]} A tuple containing the derived public key and the number of seeds used.
   */
  getTokenRecordPda(mint: PublicKey, token: PublicKey): [PublicKey, number] {
    return PublicKey.findProgramAddressSync(
      [
        Buffer.from('metadata'),
        this.solanaConstants.MPL_TOKEN_METADATA.toBuffer(),
        mint.toBuffer(),
        Buffer.from('token_record'),
        token.toBuffer()
      ],
      this.solanaConstants.MPL_TOKEN_METADATA
    );
  }

  /**
   * Generates the Program Derived Address (PDA) for the configuration account within the Gaimin PFP program.
   *
   * @returns {[PublicKey, number]} A tuple containing the derived public key and the number of seeds used.
   */
  getConfigPda(): [PublicKey, number] {
    return PublicKey.findProgramAddressSync(
      [
        Buffer.from('config')
      ],
      this.solanaConstants.GAIMIN_PFP
    );
  }

  /**
   * Generates the Program Derived Address (PDA) for the NFT account within the Gaimin PFP program associated with a specific mint.
   *
   * @param mint {PublicKey} The public key of the mint for which to retrieve the NFT account PDA.
   * @returns {[PublicKey, number]} A tuple containing the derived public key and the number of seeds used.
   */
  getNftPda(mint: PublicKey): [PublicKey, number] {
    return PublicKey.findProgramAddressSync(
      [
        Buffer.from('nft'),
        mint.toBuffer()
      ],
      this.solanaConstants.GAIMIN_PFP
    );
  }

  /**
   * Generates the Program Derived Address (PDA) for the claim account within the Gaimin PFP program associated with a specific wallet and claim seed.
   *
   * @param wallet {PublicKey} The public key of the wallet associated with the claim.
   * @param seed {Buffer} The claim seed used for generating the PDA.
   * @returns {[PublicKey, number]} A tuple containing the derived public key and the number of seeds used.
   */
  getClaimPda(wallet: PublicKey, seed: Buffer): [PublicKey, number] {
    return PublicKey.findProgramAddressSync(
      [
        Buffer.from('claim'),
        wallet.toBuffer(),
        seed
      ],
      this.solanaConstants.GAIMIN_PFP
    );
  }

}
