import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {
  isChecked: boolean = false;
  faIcon = faSquare;
  faIconChecked = faCheckSquare;

  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(public modalService: ModalService) {}

  ngOnInit(): void {}

  toggleCheckbox(): void {
    this.isChecked = !this.isChecked;
  }

  openModal() {
    const email = this.form.get('email')?.value;
    this.modalService.openAccountDeletionModal(email, this);
  }

  clearInput() {
    this.isChecked = false;
    this.form.reset();
  }
}
