// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  position: relative;
  padding: 20px 20px 15px 20px;
  background: #121212;
  color: var(--color-text);
  border-radius: 10px;
}
.modal-content__description {
  word-break: break-word;
}

.button {
  background-color: #776bf8;
  border-radius: 10px;
  padding: 10px 18px;
  color: #fff;
  width: 100%;
  border: none;
  transition: background-color 0.5s ease;
}
.button:hover {
  background-color: #5047b4;
}
.button .icon {
  margin-right: 10px;
}
.button .text {
  font-size: 16px;
  line-height: 16px;
  font-weight: 300;
}

::ng-deep ngb-modal-backdrop {
  z-index: 0 !important;
}

::ng-deep .modal-content {
  background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/switch-chain-modal/switch-chain-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,4BAAA;EACA,mBAAA;EACA,wBAAA;EACA,mBAAA;AACF;AACE;EACE,sBAAA;AACJ;;AAGA;EACE,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;EAEA,YAAA;EAEA,sCAAA;AAFF;AAIE;EACE,yBAAA;AAFJ;AAKE;EACE,kBAAA;AAHJ;AAME;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;AAJJ;;AASA;EACE,qBAAA;AANF;;AASA;EACE,uBAAA;AANF","sourcesContent":[".modal-content {\n  position: relative;\n  padding: 20px 20px 15px 20px;\n  background: #121212;\n  color: var(--color-text);\n  border-radius: 10px;\n\n  &__description {\n    word-break: break-word;\n  }\n}\n\n.button {\n  background-color: #776bf8;\n  border-radius: 10px;\n  padding: 10px 18px;\n  color: #fff;\n  width: 100%;\n\n  border: none;\n\n  transition: background-color 0.5s ease;\n\n  &:hover {\n    background-color: #5047b4;\n  }\n\n  .icon {\n    margin-right: 10px;\n  }\n\n  .text {\n    font-size: 16px;\n    line-height: 16px;\n    font-weight: 300;\n  }\n}\n\n\n::ng-deep ngb-modal-backdrop {\n  z-index: 0 !important;\n}\n\n::ng-deep .modal-content {\n  background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
