import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TOKEN_RECORD_STATE } from '../../services/solana/tools/solana.enums';
import { SolanaClaimService } from '../../services/solana/solana-claim.service';
import { SolanaPfpService } from '../../services/solana/solana-pfp.service';
import { ModalService } from '../../services/modal.service';
import { NftData } from '../../services/solana/tools/solana.interfaces';

@Component({
  selector: 'app-nfts',
  templateUrl: './nfts.component.html',
  styleUrls: ['./nfts.component.scss']
})
export class NftsComponent {

  @Input() nftsData: NftData[] | undefined;
  @ViewChild('imageElement') imageElement: ElementRef | undefined;
  @Input() walletAddress: string | undefined;

  protected readonly TOKEN_RECORD_STATE = TOKEN_RECORD_STATE;

  constructor(
    private solanaClaimService: SolanaClaimService,
    private modalService: ModalService,
    private solanaPfpService: SolanaPfpService
  ) {

  }

  onClaim(mintAddress: string) {
    this.solanaClaimService.claimAllMintAddresses$.next([mintAddress]);
    this.modalService.openClaimModal();
  };

  onStake(state: string | undefined, mintAddress: string) {
    switch (state) {
      case this.TOKEN_RECORD_STATE[0]:
        this.solanaClaimService.delegateAndStake(mintAddress).then(() => {
          setTimeout(async () => await this.solanaPfpService.getUserNFTs(this.walletAddress!), 1000);
        });
        break;
      case this.TOKEN_RECORD_STATE[1]:
        this.solanaClaimService.revokeAndUnstake(mintAddress).then(() => {
          setTimeout(async () => await this.solanaPfpService.getUserNFTs(this.walletAddress!), 1000);
        });
        break;
    }
  }

  selectNft(nftToUpdate: NftData): void {
    const updatedNftsData = this.solanaPfpService.solanaNftsData$.getValue().map((nft) => {
      if (nft.id === nftToUpdate.id) {
        return { ...nft, selected: !nft.selected };
      }
      return nft;
    });
    this.solanaPfpService.solanaNftsData$.next(updatedNftsData);
  }


}
