import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SolanaConnect } from 'solana-connect';
import { Adapter } from '@solana/wallet-adapter-base';
import { SolanaPfpService } from './solana/solana-pfp.service';
import { UtilsService } from './utils.service';
import { SolanaService } from './solana/solana.service';

@Injectable({
  providedIn: 'root'
})
export class WalletAdapterService {
  addressSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
  address$: Observable<any> = this.addressSubject.asObservable();
  solConnect = new SolanaConnect();
  walletConnectTitle = 'Wallet connect';

  constructor(private solanaPfpService: SolanaPfpService, private utilsService: UtilsService, private solanaService: SolanaService) {
  }

  /**
   * Establishes a connection to a Solana wallet through the `solConnect` service.
   * Sets up an event listener to handle wallet changes.
   * Updates internal state and calls external services based on connection status.
   */
  connect() {
    this.solConnect.openMenu();
    this.solConnect.onWalletChange(async (adapter: Adapter | any) => {
      if (adapter) {
        this.addressSubject.next(adapter.publicKey.toString());
        this.walletConnectTitle = 'Change wallet';
        await this.solanaPfpService.getWalletBalance(adapter.publicKey.toString());
        this.utilsService.setValueInSessionStorage('userPublicKey', adapter.publicKey.toString());
      } else {
        this.addressSubject.next(null);
        this.solConnect.openMenu();
        this.walletConnectTitle = 'Wallet connect';
        this.solanaPfpService.isNftLoading = false;
        this.utilsService.removeValueFromSessionStorage('userPublicKey');
        this.solanaPfpService.solanaNftsData$.next([]);
      }
    });

    this.getWallet();
  }
  /**
   * Attempts to connect to a Solana wallet using the `solanaService` and updates internal state accordingly.
   *
   * @returns {Promise<void>} A promise that resolves upon successful connection or rejects if an error occurs.
   */
  async desktopConnect(): Promise<void> {
    const publicKey = await this.solanaService.getUserPublicKey();
    this.addressSubject.next(publicKey.toString());
    await this.solanaPfpService.getWalletBalance(publicKey.toString());
    this.utilsService.setValueInSessionStorage('userPublicKey', publicKey.toString());
    this.walletConnectTitle = 'Change wallet';
  }

  /**
   * Retrieves the currently connected wallet information through the `solConnect` service.
   *
   * @returns {Promise<Adapter | null>} A promise that resolves with the connected wallet adapter object (if any), or null otherwise.
   */
  getWallet(): Adapter | null {
    return this.solConnect.getWallet();
  }
}
