import { Component } from '@angular/core';

@Component({
  selector: 'app-gaimin-testers',
  templateUrl: './gaimin-testers.component.html',
  styleUrls: ['./gaimin-testers.component.scss']
})
export class GaiminTestersComponent {
  constructor() {}
}
