import { Injectable } from '@angular/core';
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { SolanaPdaService } from './solana-pda.service';
import { SolanaParsersService } from './solana-parsers.service';
import { SolanaConstantsService } from './tools/solana-constants.service';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Umi } from '@metaplex-foundation/umi';
import {
  SearchAssetsRpcInput
} from '@metaplex-foundation/digital-asset-standard-api/dist/src/types';
import { dasApi } from '@metaplex-foundation/digital-asset-standard-api';
import { ConfigRecord, NftData, NftRecord } from './tools/solana.interfaces';
import { TOKEN_RECORD_STATE } from './tools/solana.enums';

@Injectable({
  providedIn: 'root'
})
export class SolanaPfpService {

  private endpoint: string = environment.solana.solanaRPC;
  private umi: Umi = createUmi(this.endpoint).use(dasApi());

  solanaNftsData$: BehaviorSubject<NftData[]> = new BehaviorSubject<NftData[]>([]);
  solanaBalance$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  isNftLoading: boolean = false;

  currentPage: number = 1;
  limitPerPage: number = 5;
  totalNfts: number | unknown;

  constructor(private solanaPdaService: SolanaPdaService,
              private solanaParserService: SolanaParsersService,
              private solanaConstants: SolanaConstantsService) {

  }

  /**
   * Retrieves the NFTs owned by a specific user.
   *
   * @param owner - The owner's address
   *
   * @return {Promise<NftData[]>} - A promise that resolves to an array of NftData
   */
  async getUserNFTs(owner: string): Promise<NftData[]> {
    try {
      this.isNftLoading = true;
      const config = await this.solanaParserService.parseConfig(this.solanaPdaService.getConfigPda()[0]);
      const nfts: any = await this.umi.rpc.searchAssets({
        owner,
        limit: this.limitPerPage,
        page: this.currentPage,
        creator: environment.solana.creator,
        creatorVerified: true
      } as SearchAssetsRpcInput);
      let filteredNfts: any[] = await Promise.all(
        nfts.items
      );
      this.totalNfts = nfts.total;
      if (filteredNfts.length === 0) {
        this.isNftLoading = false;
        return [];
      }

      const mappedNfts = await Promise.all(
        nfts.items.map(async (nft: any) => {
          return await this.processNFT(nft, owner, config);
        })
      );

      this.solanaNftsData$.next(mappedNfts.filter((nft) => nft !== null) as NftData[]);
      return mappedNfts as NftData[];
    } catch (error) {
      console.error('Error in getUserNFTs:', error);
      this.isNftLoading = false;
      return [];
    }
  }

  /**
   * Processes a specific NFT for create nftRecord .
   *
   * @param nft - The NFT data to process
   * @param owner - The owner's address
   * @param config - The configuration record
   *
   * @return {Promise<NftData | null>} - A promise that resolves to a NftData or null
   */
  private async processNFT(nft: NftData, owner: string, config: ConfigRecord): Promise<NftData | null> {
    try {
      const mintAddressBuff = new PublicKey(nft.id);
      const nftPda = this.solanaPdaService.getNftPda(mintAddressBuff)[0];
      const tokenAccountPda = this.solanaPdaService.getTokenAccountPda(mintAddressBuff, new PublicKey(owner))[0];
      const tokenRecordPda = this.solanaPdaService.getTokenRecordPda(mintAddressBuff, tokenAccountPda)[0];
      const parsedTokenRecord = await this.solanaParserService.parseTokenRecord(tokenRecordPda);

      try {
        const resp = await this.solanaParserService.parseNft(nftPda);
        nft.nftRecord = resp;
        nft.nftRecord.last_claimed_at = resp?.last_claimed_at!;
      } catch {
        nft.nftRecord = {
          claimed_amount: 0,
          total_amount: config.accumulated_reward + config.initial_reward,
          last_claimed_at: config.claimable_from
        };
      }


      if (!nft?.content?.links?.image) {
        const jsonUriResponse = await fetch(nft.content.json_uri);
        const jsonData = await jsonUriResponse.json();
        const imageLink = jsonData.image;
        nft.content.links = { external_url: '', image: imageLink };
      }

      nft.state = TOKEN_RECORD_STATE[parsedTokenRecord.state];
      nft.currentReward = await this.estimateClaimReward(config!, nft.nftRecord);
      nft.selected = false;

      return nft as NftData;
    } catch (error) {
      console.error('Error processing NFT:', error);
      return null;
    }
  }

  /**
   * Estimates the claim reward for a given NFT.
   *
   * @param config - The configuration record
   * @param nftRecord - The NFT record
   *
   * @return {Promise<number>} - A promise that resolves to the estimated claim reward
   */
  async estimateClaimReward(config: ConfigRecord, nftRecord: NftRecord): Promise<number> {
    const now = Date.now() / 1000;
    const stake_period = now - Number(nftRecord.last_claimed_at);
    const base_reward = nftRecord.claimed_amount === 0 ? config.initial_reward : 0;
    if (config.claimable_from > now) {
      return 0;
    }
    return Math.min(
      base_reward + (stake_period / config.accumulation_duration),
      Number(nftRecord.total_amount) - Number(nftRecord.claimed_amount)
    ) / 100;
  }

  /**
   * Retrieves the balance of a given wallet.
   *
   * @param walletAddress - The wallet address
   *
   * @return {Promise<void | number>} - A promise that resolves to the wallet balance or void
   */
  async getWalletBalance(walletAddress: string): Promise<void | number> {
    const userPublicKey = new PublicKey(walletAddress);
    try {
      return await this.solanaConstants.connection
      .getBalance(userPublicKey)
      .then((balance) => this.solanaBalance$.next(balance / LAMPORTS_PER_SOL));
    } catch (error) {
      console.error('Error fetching wallet balance:', error);
      throw error;
    }
  }

  /**
   * Refreshes the nfts and user balance.
   *
   * @param userWalletAddress - The user wallet address
   *
   * @return {Promise<void>} - A promise that resolves when the data has been refreshed
   */
  async refreshData(userWalletAddress: string): Promise<void> {
    await this.getUserNFTs(userWalletAddress);
    await this.getWalletBalance(userWalletAddress);
  }

}
