import { NFTs } from './genesis.interfaces';

export default [
  {
    id: 'ethereum.0x67b630e70e793d48f1329c00cad9d60977605df5.1',
    tokenId: 1,
    name: 'GAIMIN Genesis - Silver',
    imageUrl:
      'https://lh3.googleusercontent.com/O9mvWxXrNycnVzivjdDvGAgSvc_N8c7LnMTqrkcTgrRY1uUijc0L3xACNDjEd8U3EqAewrRgNbjMS3XaL1FWn_VUN75r_2d5xS1Bt1k=w650',
    reward: {
      month: 8888
    }
  },
  {
    id: 'ethereum.0x67b630e70e793d48f1329c00cad9d60977605df5.2',
    tokenId: 2,
    name: 'GAIMIN Genesis - Gold',
    imageUrl:
      'https://lh3.googleusercontent.com/SpwzC-yOVHAuIb2ImKaTm8_ZU3RoV5zrK2f7HBWCNB-TrhQlV0sWHdtO-Uu-CDmAlnFbnw5TTzTRE4RPehADl3fr73fotQd-0qGg97Y=w650',
    reward: {
      month: 22222
    }
  },
  {
    id: 'ethereum.0x67b630e70e793d48f1329c00cad9d60977605df5.3',
    tokenId: 3,
    name: 'GAIMIN Genesis - Black Gold',
    imageUrl:
      'https://lh3.googleusercontent.com/oOHMzTeOkgk3Xm3-9HO_XAYKFpKnP9UBj5AHMNjBukKwZjO0Ctm2dZZpKxG_UQ5r4eDmJ6vIsEj70oKHBCMnIKTLHybkMnj0iz4v=w650',
    reward: {
      month: 88888
    }
  }
] as NFTs;
