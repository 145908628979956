import { Component } from '@angular/core';
import { ModalService } from '../../../../shared/services/modal.service';

@Component({
  selector: 'app-testers-banner',
  templateUrl: './testers-banner.component.html',
  styleUrls: ['./testers-banner.component.scss']
})
export class TestersBannerComponent {
  constructor(public modalService: ModalService) {}
}
