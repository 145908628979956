import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@angular/cdk/layout';
import { NgxTurnstileModule } from "ngx-turnstile";


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { WithdrawalConfirmationComponent } from './pages/withdrawal-confirmation/withdrawal-confirmation.component';
import { GaiminGgComponent } from './pages/gaimin-gg/gaimin-gg.component';
import { GaiminTestersComponent } from './pages/gaimin-testers/gaimin-testers.component';
import { NftValidationComponent } from './pages/nft-validation/nft-validation.component';
import { HelpAntivirusComponent } from './pages/help-antivirus/help-antivirus.component';
import { SuccessPageComponent } from './pages/success-page/success-page.component';
import { FailedPageComponent } from './pages/failed-page/failed-page.component';

import { MainButtonComponent } from './shared/components/main-button/main-button.component';

import { TestersSignupComponent } from './pages/gaimin-testers/sections/testers-signup/testers-signup.component';
import { TestersBannerComponent } from './pages/gaimin-testers/sections/testers-banner/testers-banner.component';

import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { MainBannerComponent } from './shared/components/main-banner/main-banner.component';
import { ScrollTopComponent } from './shared/components/scroll-top/scroll-top.component';
import { WhitelistedModalComponent } from './shared/components/whitelisted-modal/whitelisted-modal.component';


import { HeaderInterceptor } from './shared/header.interceptor';
import { ChatComponent } from './shared/components/chat/chat.component';
import { DeleteAccountComponent } from './pages/delete-account/delete-account.component';
import { DeleteAccountModalComponent } from './shared/components/delete-account-modal/delete-account-modal.component';
import { SolanaWalletLoginComponent } from './pages/solana-wallet-login/solana-wallet-login.component';
import { SolanaTransactionComponent } from './pages/solana-transaction/solana-transaction.component';
import { SolanaClaimNftComponent } from './pages/solana-claim-nft/solana-claim-nft.component';
import { SolanaContentComponent } from './shared/components/solana-content/solana-content.component';
import { SolanaPfpNftComponent } from './pages/solana-pfp-nft/solana-pfp-nft.component';
import { NftItemComponent } from './shared/components/nfts/nft-item/nft-item.component';
import { NftsComponent } from './shared/components/nfts/nfts.component';
import { ClaimRewardModalComponent } from './shared/components/claim-reward-modal/claim-reward-modal.component';
import { EmptyComponent } from './shared/components/empty/empty.component';

import { SolanaRewardsInfoComponent } from './pages/solana-rewards-info/solana-rewards-info.component';
import { StakeConfirmationModalComponent } from './shared/components/stake-confirmation-modal/stake-confirmation-modal.component';
import { EthereumGenesisComponent } from './pages/ethereum-genesis/ethereum-genesis.component';
import { ApprovalClaimModalComponent } from './shared/components/approval-claim-modal/approval-claim-modal.component';
import { SuccessClaimModalComponent } from './shared/components/success-claim-modal/success-claim-modal.component';
import { GenesisNftCardComponent } from './pages/ethereum-genesis/genesis-nft-card/genesis-nft-card.component';
import { SwitchChainModalComponent } from './shared/components/switch-chain-modal/switch-chain-modal.component';
import { CaptchaComponent } from './pages/captcha-page/captcha/captcha.component';
import { CaptchaPageComponent } from './pages/captcha-page/captcha-page.component';

@NgModule({
  declarations: [
    AppComponent,
    WithdrawalConfirmationComponent,
    GaiminGgComponent,
    GaiminTestersComponent,
    NftValidationComponent,
    HelpAntivirusComponent,
    SuccessPageComponent,
    FailedPageComponent,
    MainButtonComponent,
    HeaderComponent,
    FooterComponent,
    MainBannerComponent,
    ScrollTopComponent,
    WhitelistedModalComponent,
    TestersSignupComponent,
    TestersBannerComponent,
    ChatComponent,
    DeleteAccountComponent,
    DeleteAccountModalComponent,
    SolanaWalletLoginComponent,
    SolanaTransactionComponent,
    SolanaClaimNftComponent,
    SolanaContentComponent,
    SolanaPfpNftComponent,
    NftItemComponent,
    NftsComponent,
    ClaimRewardModalComponent,
    EmptyComponent,
    SolanaContentComponent,
    SolanaRewardsInfoComponent,
    StakeConfirmationModalComponent,
    EthereumGenesisComponent,
    ApprovalClaimModalComponent,
    SuccessClaimModalComponent,
    GenesisNftCardComponent,
    SwitchChainModalComponent,
    CaptchaComponent,
    CaptchaPageComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      progressBar: true,
      closeButton: true,
      extendedTimeOut: 3000,
      disableTimeOut: 'timeOut',
      preventDuplicates: true
    }),
    NgbTooltip,
    LayoutModule,
    NgxTurnstileModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule {}
