import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Address } from '@wagmi/core';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../shared/services/modal.service';
import { GenesisService } from '../../shared/services/genesis/genesis.service';

@Component({
  selector: 'app-ethereum-genesis',
  templateUrl: './ethereum-genesis.component.html',
  styleUrls: ['./ethereum-genesis.component.scss']
})
export class EthereumGenesisComponent implements OnInit, OnDestroy {
  private getNftsSubscription?: Subscription;
  private walletAddressSubscription?: Subscription;

  isNftsLoading: boolean = false;

  walletAddress: Address | undefined;

  isUnlockingAll: boolean = false;
  isLockingAll: boolean = false;
  isUnlocking: boolean = false;
  isLocking: boolean = false;

  constructor(
    public genesisService: GenesisService,
    private clipboardService: ClipboardService,
    private toastrService: ToastrService,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.walletAddressSubscription = this.genesisService.wallet.address$.subscribe({
      next: (walletAddress: Address | undefined) => {
        this.walletAddress = walletAddress;

        if (walletAddress != null) {
          this.updateNftsData();
        }
      }
    });
  }

  get isInProgress() {
    return this.isLocking || this.isUnlocking || this.isUnlockingAll || this.isLockingAll;
  }

  get isUnlockDisabled() {
    return this.genesisService.selected.lockedIds.length === 0 || this.genesisService.selected.unlockedIds.length > 0;
  }

  get isLockDisabled() {
    return this.genesisService.selected.unlockedIds.length === 0 || this.genesisService.selected.lockedIds.length > 0;
  }

  get isUnlockAllDisabled() {
    return this.genesisService.userNfts.lockedNfts.length === 0;
  }

  get isLockAllDisabled() {
    return this.genesisService.userNfts.unlockedNfts.length === 0;
  }

  connectWallet() {
    this.genesisService.openConnectWalletModal();
  }

  disconnectWallet() {
    this.genesisService.disconnectWallet();
  }

  onCopyWalletAddress(walletAddress: Address = this.walletAddress!) {
    this.clipboardService.copy(walletAddress);
    this.toastrService.success(`The address "${walletAddress}" copied.`);
  }

  updateNftsData() {
    this.genesisService.updateUserNftsData().subscribe({
      next: () => {
        console.log('updateNftsData');
      }
    });
  }

  onSelectUnlocked(isSelected: boolean, nftId: number) {
    if (isSelected) {
      this.genesisService.selected.unlockedIds.push(nftId);
    } else {
      this.genesisService.selected.unlockedIds = this.genesisService.selected.unlockedIds.filter(
        (id: number) => id !== nftId
      );
    }
  }

  onSelectLocked(isSelected: boolean, nftId: number) {
    if (isSelected) {
      this.genesisService.selected.lockedIds.push(nftId);
    } else {
      this.genesisService.selected.lockedIds = this.genesisService.selected.lockedIds.filter(
        (id: number) => id !== nftId
      );
    }
  }

  unlockAllNfts() {
    this.isUnlockingAll = true;

    this.genesisService.unlockAll().subscribe({
      next: () => {
        console.log('Unlocked All');
      },
      complete: () => {
        this.isUnlockingAll = false;
      }
    });
  }

  lockAllNfts() {
    this.isLockingAll = true;

    this.genesisService.lockAll().subscribe({
      next: () => {
        console.log('Locked All');
      },
      complete: () => {
        this.isLockingAll = false;
      }
    });
  }

  unlockSelectedNFTs() {
    this.isUnlocking = true;

    this.genesisService.unlock(this.genesisService.selected.lockedIds).subscribe({
      next: () => {
        console.log('Unlocked');
      },
      complete: () => {
        this.isUnlocking = false;
      }
    });
  }

  lockSelectedNFTs() {
    this.isLocking = true;

    this.genesisService.lockNfts(this.genesisService.selected.unlockedIds).subscribe({
      next: () => {
        console.log('Locked');
      },
      complete: () => {
        this.isLocking = false;
      }
    });
  }

  claimAllNFTs() {
    this.modalService.openApprovalClaimAllNftsModal();
  }

  ngOnDestroy() {
    this.getNftsSubscription?.unsubscribe();
    this.walletAddressSubscription?.unsubscribe();
  }
}
