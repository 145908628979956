import { Component } from '@angular/core';
import { TurnstileService } from '../../shared/services/turnstile.service';

@Component({
  selector: 'app-captcha-page',
  templateUrl: './captcha-page.component.html',
  styleUrls: ['./captcha-page.component.scss']
})
export class CaptchaPageComponent {

  constructor(public  turnstileService: TurnstileService) {
  }


}
