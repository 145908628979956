import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { GenesisService } from '../../services/genesis/genesis.service';
import { EVENT_TYPE } from '../../services/genesis/genesis.enums';

@Component({
  selector: 'app-success-claim-modal',
  templateUrl: './success-claim-modal.component.html',
  styleUrls: ['./success-claim-modal.component.scss']
})
export class SuccessClaimModalComponent implements OnInit {
  private link: string = environment.type === 'prod' ? 'https://etherscan.io/tx/' : 'https://sepolia.etherscan.io/tx/';
  transactionHash: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private genesisService: GenesisService
  ) {}

  ngOnInit(): void {
    this.genesisService.waitUntilTransactionEventAndUpdateUserNfts(EVENT_TYPE.CLAIM).subscribe({
      next: () => {
        console.log('Claimed and updated!');
      }
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  get fullLink(): string {
    return this.link + this.transactionHash;
  }
}
