import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Address, readContract, writeContract, WriteContractResult } from '@wagmi/core';
import { TokenId } from './genesis.interfaces';
import nftsAbi from './abis/nfts.abi';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenesisNftsService {
  readonly SMART_CONTRACT_ADDRESS: Address = environment.genesis.smartContracts.nfts as Address;
  readonly AVAILABLE_NFT_IDS: TokenId[] = [1, 2, 3];

  /**
   * Calls "balanceOfBatch" function inside smart contract
   *
   * @param walletAddress Wallet address for getting balance
   * @param nftIds We get balances for this nft ids
   *
   * @return {bigint[]} Array with count nfts. Placed in the same order as ids in {nftIds} param
   */
  balanceOfBatch(walletAddress: Address, nftIds: TokenId[] = this.AVAILABLE_NFT_IDS): Observable<bigint[]> {
    const walletAddresses: Address[] = Array(nftIds.length).fill(walletAddress);

    const configuration = {
      address: this.SMART_CONTRACT_ADDRESS,
      abi: nftsAbi,
      functionName: 'balanceOfBatch',
      args: [walletAddresses, nftIds]
    };

    return from(readContract(configuration) as Promise<bigint[]>);
  }

  /**
   * Set available for send nfts. Wait until status change in smart contract!!!
   *
   * @param approved Set approval status
   * @param walletAddress User wallet address for check is approval already same
   * @param operator Smart contract address. Allows to get nfts from this address
   *
   * @return {WriteContractResult} Result of writing contract
   */
  setApprovalForAll(
    approved: boolean,
    walletAddress: Address,
    operator: Address
  ): Observable<WriteContractResult | undefined> {
    return this.isApprovedForAll(walletAddress, operator).pipe(
      switchMap((isApproved: boolean) => {
        if (isApproved === approved) {
          return of(undefined);
        }

        const configuration = {
          address: this.SMART_CONTRACT_ADDRESS,
          abi: nftsAbi,
          functionName: 'setApprovalForAll',
          args: [operator, approved]
        };

        return from(writeContract(configuration));
      })
    );
  }

  /**
   * Check is approved to send nfts
   *
   * Calls "isApprovedForAll" function inside smart contract
   *
   * @param operator Smart contract address. Allows to get nfts from this address
   * @param walletAddress Address to check
   *
   * @return {boolean} is already approved
   */
  isApprovedForAll(walletAddress: Address, operator: Address): Observable<boolean> {
    const configuration = {
      address: this.SMART_CONTRACT_ADDRESS,
      abi: nftsAbi,
      functionName: 'isApprovedForAll',
      args: [walletAddress, operator]
    };

    return from(readContract(configuration) as Promise<boolean>);
  }
}
