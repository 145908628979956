import { Injectable } from '@angular/core';
import { Cluster, clusterApiUrl, Connection, PublicKey } from '@solana/web3.js';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolanaConstantsService {
  public connection: Connection;
  public clusterConnection: Connection;
  public SPL_ASSOCIATED_TOKEN_ACCOUNT: PublicKey;
  public GAIMIN_PFP: PublicKey;
  public TOKEN_PROGRAM_ID: PublicKey;
  public MPL_TOKEN_METADATA: PublicKey;
  public GAIMIN_PFP_INSTRUCTIONS;
  public MPL_INSTRUCTIONS;
  public MPL_DELEGATE_TYPES;
  public MPL_ACCOUNT;
  public MPL_ACCOUNT_AUTH;

  constructor() {
    this.connection = new Connection(environment.solana.solanaRPC, 'confirmed');
    this.clusterConnection = new Connection(clusterApiUrl(environment.solana.solanaCluster as Cluster));
    this.SPL_ASSOCIATED_TOKEN_ACCOUNT = new PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL');
    this.GAIMIN_PFP = new PublicKey('GMRXrgb2TF6ejGt3nJrUAkwVoKUrnVK5LZ6duRE8x47g');
    this.TOKEN_PROGRAM_ID = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA');
    this.MPL_TOKEN_METADATA = new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');
    this.MPL_ACCOUNT_AUTH = environment.solana.MPL_ACCOUNT_AUTH;
    this.MPL_ACCOUNT = environment.solana.MPL_ACCOUNT;
    this.GAIMIN_PFP_INSTRUCTIONS = {
      CONFIG: 0,
      DELETE: 1,
      NFT: 2,
      CREATE_CLAIM: 3,
      CLAIM: 4
    };
    this.MPL_INSTRUCTIONS = { delegate: 44, revoke: 45, lock: 46, unlock: 47 };
    this.MPL_DELEGATE_TYPES = { staking: 5 };
  }
}
