import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { WhitelistedService } from '../../../../shared/services/whitelisted.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-testers-signup',
  templateUrl: './testers-signup.component.html',
  styleUrls: ['./testers-signup.component.scss']
})
export class TestersSignupComponent implements OnInit {
  list: any[] = [];
  lastItem: number = 0;
  selectedIndex: number = 0;
  faIcon = faSquare;
  faIconChecked = faCheckSquare;
  checkboxMessages: string[] = ['YES, I understand', 'YES, I agree please send the download link'];
  checkboxText: string = this.checkboxMessages[0];

  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern('([A-Za-z- ]+)'), Validators.maxLength(30)]),
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(private whiteListedService: WhitelistedService, private toastr: ToastrService, private router: Router) {}

  ngOnInit(): void {
    this.list = [
      {
        title: 'GAIMIN seems to use a lot of my GPU, what is happening?',
        description: `<p>The GAIMIN platform basically auto-adjusts the amount of spare GPU used for monetization, depending on what
        else your PC is doing, this is perfectly normal and is exactly how it is designed to work.</p>
        <p>Should you start to game or perform any GPU-intensive task on your PC, then GPU is "freed up" from monetization accordingly and
        GAIMIN will simply continue to work in the background using less of your GPU.</p>
        <p>The more powerful your GPU, the more monetization you will achieve. Any user with a modern medium to high-powered GPU will not even notice
        GAIMIN running in the background.</p>
        <p>Note: GAIMIN will never overclock your GPU, or ask it to do anything it
        isn't designed to easily and comfortably do, everything GAIMIN does on your PC is simple and easy for your GPU
        to handle without any issues at all. GPUS are designed to process data, and that's exactly what we do with it!</p>
        <p><u>Summary:</u> GAIMIN only uses spare GPU and the amount dedicated to monetization auto-adjusts so as not to affect
        the user's gaming experience. It “invisibly” works in the background.</p>`,
        isChecked: false
      },
      {
        title: 'Does the GAIMIN application cause wear and tear or degradation of my GPU?',
        description: `<p>GPU degradation, (like other electronic component degradation) is basically a myth. There are many YouTube
        videos that demonstrate this, here is a good one: <a href="https://www.youtube.com/watch?v=hKqVvXTanzI" target="_blank">https://www.youtube.com/watch?v=hKqVvXTanzI</a></p>
        <p>Electronic components such as those inside GPUs either work or they don't (it's binary), they do not degrade per se, or
        lose any significant performance over their lifespan. They simply stop working completely when they reach the
        end of their life which is typically 8-10 years of intense use for a gaming GPU.</p>
        <p>When using the right settings and being managed properly (something our GAIMIN platform automatically does),
        prolonged computational activity such as performing blockchain computations and gaming do not unduly
        degrade your GPU's physical integrity in any way. Again you can research this online independently.</p>
        <p>Of course, standard maintenance of
        your gaming PC, like keeping fans and cooling vents dust-free (this is good practice for any gamer and you
        should do this anyway) help GPU life. Blockchain mining doesn't put any more stress on your GPU than playing a
        modern game with good graphics. Again this has been very well documented and researched.</p>
        <p>Also note, almost in every case GPUs are replaced long before they reach the end of their useful life.
        Think of a GPU like a car,
        it's capable of doing 1 million miles before it “breaks”, but nobody keeps a car that long, people always
        change their car before it wears out because they want a newer “better” one, that is exactly the same with a
        gaming PC/GPU. The gaming industry advances so quickly that no serious gamer will be using the same GPU in 10
        years' time, probably not even in 5 years' time.</p>
        <p><u>Summary:</u> GPU degradation is not a justified concern.</p>`,
        isChecked: false
      },
      {
        title: 'What about electricity cost, will my rewards cover this cost?',
        description: `<p>We have never produced any tests with our GAIMIN application where electricity costs were not easily covered
        with the regards gained. On this excellent website you can see figures for yourself here (you can enter your
        electric costs and calculate them) <a href="https://whattomine.com/gpus" target="_blank">https://whattomine.com/gpus</a>.</p>
        <p>Even during the infamous "crypto winter" of
        2018, with very low mining rewards, when we tested our Alpha version of GAIMIN (as proof of concept), we never
        once produced a single instance of electricity being higher than the earned rewards – simply because our A.I.
        auto-selected the most profitable coins to mine, and maintained a healthy return. This is detailed in our
        Whitepaper and a report we produced called the "Alpha Report" after we produced the Alpha version of our
        platform.</p>
        <p>In addition, don't forget, GAIMIN is designed to run in the background and only uses the RAM of the
        spare GPU power for monetization. That means the gamer already has his PC turned on, the screen already on,
        etc. So the true additional power use of GAIMIN working in the background is minimal, and of course – on any
        referral rewards (from introducing friends) you have zero power costs.</p>
        <p><u>Summary:</u> The additional power costs of
        running GAIMIN are negligible (less than running any modern game) because the PC is already on, and earned
        rewards are – of course – always significantly higher than any power cost. Plus future monetization options,
        like rendering, are even more profitable.</p>`,
        isChecked: false
      },
      {
        title: 'What about the qualification on levels 2 and 3 of the NEP referral program?',
        description: `<p>On our Network Expansion referral Program (NEP), you automatically get 10% of rewards from the hashpower
        generated from any gamer friend you introduce to GAIMIN via your unique NEP referral link. However, should
        they introduce people you will need to "qualify" to receive the 10% on this 2 (and 3rd) level by running
        GAIMIN a minimum of 10 hours per week in the background.</p>
        <p>This qualification requirement ensures that we build
        an active user base, thus ensuring we have the GPU processing power to execute more profitable monetization
        strategies like rendering, in the future. The "qualification" also ensures all GAIMIN users have consistent
        and higher ongoing rewards. The 10 hours of running GAIMIN in the background is unnoticeable for a user.</p>
        <p><u>Summary:</u> The 10 hour per week qualification (GAIMIN app silently running in the background) benefits everyone,
        and is very easy to achieve for a gamer.</p>`,
        isChecked: false
      },
      {
        title: 'I get an anti-virus notification that the miners GAIMIN use contain malware?',
        description: `<p>We use industry-standard, highly proven miners, and right now that's how some anti-virus softwares flag
        miners. It is a common issue for any software that uses GPUs to do blockchain computations. There is obviously
        no malware contained within the miners. We have extensive help PDFs to guide a user through the simple setup
        to deal with this issue (<a href="https://help.gaimin.gg/help/antivirus/" target="_blank">https://help.gaimin.gg/help/antivirus/</a>).
        Approx 1000 combined Alpha and Beta testers
        have used the PDFs to assure correct and safe installation of GAIMIN. We have plans to code our own miners to
        minimize this issue in the future, but for now you may need to use the help guides (link above) to assist you
        with installation if this happens. It's very easy to do. And you will have full support from our tech team if
        needed.</p>
        <p><u>Summary:</u> This is a standard industry issue that we are working on to improve. There are simple guides
        to help install as well as our tech support.</p>`,
        isChecked: false
      },
      {
        title: 'Can you explain the pending rewards shown in both USDT and GMRX?',
        description: `<p>Until our GMRX token is listed the rewards are shown in USDT (and can be withdrawn in USDT), for now the GMRX
        display is purely a representation of the USDT value in GMRX at a $0.01 conversion.</p>`,
        isChecked: false
      },
      {
        title: 'Why are rewards lower on certain LHR GPUs and on laptops?',
        description: `<p>Rewards are lower for certain LHR (which means Lite/Low Hash Rate) GPUs – because they have been "restricted"
        by the manufacturer. This is something our dev team is working on now, to enable dual coin mining and increase
        rewards.
        (<a href="https://levelup.gitconnected.com/unlock-the-full-potential-of-low-hash-rate-gpus-with-dual-mining-8cf343c0c968" target="_blank">https://levelup.gitconnected.com/unlock-the-full-potential-of-low-hash-rate-gpus-with-dual-mining-8cf343c0c968</a>).
        This may be the case with your PC if you see lower than expected rewards.</p>
        <p>Also, note that laptop rewards are
        significantly lower than desktops for the following three reasons: </p>
        <p>- Often laptop GPUs are different, even if it
        says (for example) GTX 1060, the laptop version often has a prefix like an "M" which means mobile and produced
        less hash <p>
        <p>- The power supply to a laptop is inferior. Most laptops have ~65 Watt chargers, and modern GPUs
        consume more than that, so they run in some kind of power-saving mode. </p>
        <p>- Additionally, heating can be an issue
        as laptops have lower cooling capacity than desktops (extra fans, vents, open-air coolers, modern liquid
        coolers, etc).</p>`,
        isChecked: false
      },
      {
        title: 'A.I. Testing, Airdrops, Bonus GMRX, Bonus NFTs',
        description: `<p>Part of the next phase of development of the GAIMIN platform will be to further enhance our A.I. in order to improve our machine learning capability and enable us to increase rewards in future upgrades. Because of this, the rewards you earn during this testing period may be less than optimal, as we dedicate some of your computational resources towards this goal.</p>
        <p>With this in mind, we plan to compensate you with rewards such as airdrops both in GMRX, and in a Polygon/MATIC stable coin, additional NFTs, and a few other surprises.</p>
        <p>In order to do this, you will be required to open a <a href="https://www.venly.io/" target="_blank">Venly | Home</a> wallet (the wallet we have integrated into GAIMIN). It’s very simple and takes less than 1 minute. You will need to add Polygon/MATIC as one of the wallets within your Venly account, as all rewards will be issued to this wallet address.</p>`,
        isChecked: false
      },
      {
        title: 'What exactly is my role as a GAIMIN elite tester group?',
        description: `<p>Your role is as follows:</p>
        <p>- Download and install GAIMIN</p>
        <p>- Make sure you are monetizing (you'll see the rewards indicator moving)</p>
        <p>- Leave it running in the background while you work and game for as long as you can</p>
        <p>- Play in our GAIMCRAFT (our amazing custom Minecraft server)</p>
        <p>- Try and "break" anything you can, find any errors, bugs and report them all to <a href="mailto:calvin@gaimin.io">calvin@gaimin.io</a>
        NOTE: there will be special "bug bounties"</p>
        <p>- Register for a Venly.io wallet (easy, just go here: <a href="https://wallet.venly.io/" target="_blank">https://wallet.venly.io/</a>
        and sign up with, for example, your Google account)</p>
        <p>- Use this wallet within the GAIMIN platform (we'll send you the GMRX and NFTs you earn as a tester to it)</p>
        <p>- Use your NEP Squad referral link and send it to a gamer friend(s) with a good GPU, so they can join the test and earn
        their own rewards… and you'll earn 10% of all their efforts</p>
        <p>- Send us periodic screenshots of your rewards</p>
        <p>- Report anything you want to <a href="mailto:calvin@gaimin.io">calvin@gaimin.io</a> – for example, thing you like or dislike, any thoughts, feedback,
        suggestions, ideas, possible improvements, etc.</p>
        <p>- Please feel free to inundate our support team with questions and support issues (either in the Discord support channel,
        or by using the GAIMIN platform built in support ticket option, or by emailing <a href="mailto:calvin@gaimin.io">calvin@gaimin.io</a>)</p>
        <p>That's it guys.</p>
        <p>Click on the button below to confirm you agree and we'll send you an email with the download link and
        activation code so you can get started</p>`,
        isChecked: false
      }
    ];

    this.lastItem = this.list.length - 1;
  }

  changeCheckbox(index: number) {
    this.list[index].isChecked = !this.list[index].isChecked;
  }

  prevPage(index: number) {
    this.selectedIndex = index - 1;
    this.checkboxText = this.checkboxMessages[0];
  }

  nextPage(index: number) {
    if (index < this.lastItem) {
      this.selectedIndex = index + 1;
    }
    this.checkboxText = this.selectedIndex == this.lastItem ? this.checkboxMessages[1] : this.checkboxMessages[0];
  }

  onSubmit() {
    this.whiteListedService.tagLeadAsTester(this.form.value).subscribe(
      (response) => {
        if (response.success) {
          this.router.navigateByUrl('/');
          this.toastr.success(
            'We will send you an email with the download link and unique, one-time-use activation code!'
          );
        } else {
          this.toastr.error(`${response.error?.description}`, 'Something went wrong!');
        }
      },
      (error) => {
        this.toastr.error(error, 'Something went wrong!');
      }
    );
  }
}
