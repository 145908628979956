// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  font-family: "Poppins", "Open Sans", "Arial", sans-serif;
}

.wrapper {
  position: relative;
  height: 100%;
  background-color: #101011;
}
.wrapper .header__wrapper {
  max-width: 165px;
  width: 100%;
  margin: 0 auto;
  padding-top: 70px;
  text-align: center;
}
.wrapper .header__image {
  width: 100%;
}
.wrapper .main__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wrapper .main__content {
  padding: 110px;
  text-align: center;
  border: 1px solid #22222b;
  border-radius: 10px;
}
.wrapper .main__content-image {
  padding-bottom: 20px;
}
.wrapper .main__content-image-img {
  width: 45px;
  height: 45px;
}

.load-7 .square {
  animation: loadingG 2.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

.square {
  width: 46px;
  height: 50px;
  border-radius: 4px;
  background: no-repeat url("https://storage.googleapis.com/gaimin-images/ghost.svg");
}

@keyframes loadingG {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(300px, 0) rotate(360deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
.square-holder {
  padding-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/solana-content/solana-content.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,wDAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,yBAAA;AACF;AAEI;EACE,gBAAA;EACA,WAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;AAAN;AAGI;EACE,WAAA;AADN;AAMI;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AAJN;AAOI;EACE,cAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;AALN;AAOM;EACE,oBAAA;AALR;AAOQ;EACE,WAAA;EACA,YAAA;AALV;;AAWA;EACE,sEAAA;AARF;;AAWA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mFAAA;AARF;;AAUA;EACE;IACE,uCAAA;EAPF;EASA;IACE,6CAAA;EAPF;EASA;IACE,uCAAA;EAPF;AACF;AAUA;EACE,oBAAA;AARF","sourcesContent":[":host {\n  width: 100%;\n  font-family: 'Poppins', 'Open Sans', 'Arial', sans-serif;\n}\n\n.wrapper {\n  position: relative;\n  height: 100%;\n  background-color: #101011;\n\n  .header {\n    &__wrapper {\n      max-width: 165px;\n      width: 100%;\n      margin: 0 auto;\n      padding-top: 70px;\n      text-align: center;\n    }\n\n    &__image {\n      width: 100%;\n    }\n  }\n\n  .main {\n    &__wrapper {\n      position: absolute;\n      top: 50%;\n      left: 50%;\n      transform: translate(-50%, -50%);\n    }\n\n    &__content {\n      padding: 110px;\n      text-align: center;\n      border: 1px solid #22222b;\n      border-radius: 10px;\n\n      &-image {\n        padding-bottom: 20px;\n\n        &-img {\n          width: 45px;\n          height: 45px;\n        }\n      }\n    }\n  }\n}\n.load-7 .square {\n  animation: loadingG 2.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;\n}\n\n.square {\n  width: 46px;\n  height: 50px;\n  border-radius: 4px;\n  background: no-repeat url('https://storage.googleapis.com/gaimin-images/ghost.svg');\n}\n@keyframes loadingG {\n  0% {\n    transform: translate(0, 0) rotate(0deg);\n  }\n  50% {\n    transform: translate(300px, 0) rotate(360deg);\n  }\n  100% {\n    transform: translate(0, 0) rotate(0deg);\n  }\n}\n\n.square-holder {\n  padding-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
