// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  padding: 15px 1rem;
  transition: 0.4s;
  max-width: 1300px;
  margin: 0 auto;
}
.navbar__wrapper {
  height: 62px;
  background: #000;
  width: 100%;
  transition: 0.5s all;
  padding: 10px 1rem;
}
.navbar-logo {
  width: 150px;
}
.navbar-on-scroll {
  padding: 0 1rem;
  transition: 0.5s all;
}
.navbar-on-scroll ::ng-deep app-main-button button {
  color: var(--color-text);
}
.navbar-dark .navbar-nav .nav-link {
  color: var(--color-highlight-text);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  transition: 0.4s;
}
.navbar-dark .navbar-nav .nav-link:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 991px) {
  .navbar__wrapper {
    height: 45px;
    background: #000;
  }
  .navbar-logo {
    width: 90px;
  }
  .navbar-collapse {
    background: #000000;
    padding: 10px;
    border-bottom: 3px solid var(--color-highlight-text);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/header/header.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AACF;AACE;EACE,YAAA;EACA,gBAAA;EACA,WAAA;EACA,oBAAA;EACA,kBAAA;AACJ;AAEE;EACE,YAAA;AAAJ;AAGE;EACE,eAAA;EACA,oBAAA;AADJ;AAIQ;EACE,wBAAA;AAFV;AAQE;EACE,kCAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AANJ;AAOI;EACE,YAAA;AALN;;AAUA;EAEI;IACE,YAAA;IACA,gBAAA;EARJ;EAWE;IACE,WAAA;EATJ;EAYE;IACE,mBAAA;IACA,aAAA;IACA,oDAAA;EAVJ;AACF","sourcesContent":[".navbar {\n  padding: 15px 1rem;\n  transition: 0.4s;\n  max-width: 1300px;\n  margin: 0 auto;\n\n  &__wrapper {\n    height: 62px;\n    background: #000;\n    width: 100%;\n    transition: 0.5s all;\n    padding: 10px 1rem;\n  }\n\n  &-logo {\n    width: 150px;\n  }\n\n  &-on-scroll {\n    padding: 0 1rem;\n    transition: 0.5s all;\n    ::ng-deep {\n      app-main-button {\n        button {\n          color: var(--color-text);\n        }\n      }\n    }\n  }\n\n  &-dark &-nav .nav-link {\n    color: var(--color-highlight-text);\n    font-size: 14px;\n    font-weight: 300;\n    letter-spacing: 1px;\n    transition: 0.4s;\n    &:hover {\n      opacity: 0.7;\n    }\n  }\n}\n\n@media only screen and (max-width: 991px) {\n  .navbar {\n    &__wrapper {\n      height: 45px;\n      background: #000;\n    }\n\n    &-logo {\n      width: 90px;\n    }\n\n    &-collapse {\n      background: #000000;\n      padding: 10px;\n      border-bottom: 3px solid var(--color-highlight-text);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
