import { Component } from '@angular/core';

@Component({
  selector: 'app-help-antivirus',
  templateUrl: './help-antivirus.component.html',
  styleUrls: ['./help-antivirus.component.scss']
})
export class HelpAntivirusComponent {
  readonly antivirusesList = [
    {
      name: 'Windows Defender',
      imageUrl: 'assets/antiviruses/windows-defender.png',
      link: 'https://www.youtube.com/watch?v=v9Y8olgJEIo&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=1'
    },
    {
      name: 'AVG',
      imageUrl: 'assets/antiviruses/avg.png',
      link: 'https://www.youtube.com/watch?v=PkpMkKlZ3rw&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=5'
    },
    {
      name: 'BullGuard',
      imageUrl: 'assets/antiviruses/bullguard.png',
      link: 'https://www.youtube.com/watch?v=NYcdGezW_kI&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=4&t=94s'
    },
    {
      name: 'F-Secure',
      imageUrl: 'assets/antiviruses/f-secure.png',
      link: 'https://www.youtube.com/watch?v=SzNkAFiWmLc&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=10'
    },
    {
      name: 'Bitdefender',
      imageUrl: 'assets/antiviruses/bitdefender.png',
      link: 'https://www.youtube.com/watch?v=_jE74JROfvE&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=8'
    },
    {
      name: 'GDATA',
      imageUrl: 'assets/antiviruses/gdata.png',
      link: 'https://www.youtube.com/watch?v=sDr-WW--zFE&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=15'
    },
    {
      name: 'Avast',
      imageUrl: 'assets/antiviruses/avast.png',
      link: 'https://www.youtube.com/watch?v=4shbk_Bf_Sg&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=6'
    },
    {
      name: 'ESET',
      imageUrl: 'assets/antiviruses/eset.png',
      link: 'https://www.youtube.com/watch?v=Qr-uJw0cxvk&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=3&t=168s'
    },
    {
      name: 'MalwareBytes',
      imageUrl: 'assets/antiviruses/malware-bytes.png',
      link: 'https://www.youtube.com/watch?v=WopvqEs5U0Y&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=14'
    },
    {
      name: 'Kaspersky',
      imageUrl: 'assets/antiviruses/kaspersky.png',
      link: 'https://www.youtube.com/watch?v=mQVBwR1vORo&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=2&t=148s'
    },
    {
      name: 'Comodo',
      imageUrl: 'assets/antiviruses/comodo.png',
      link: 'https://www.youtube.com/watch?v=VgSMjBcidbo&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=12'
    },
    {
      name: 'Avira',
      imageUrl: 'assets/antiviruses/avira.png',
      link: 'https://www.youtube.com/watch?v=ce4OMUsFPpg&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=9'
    },
    {
      name: 'McAfee',
      imageUrl: 'assets/antiviruses/mcafee.png',
      link: 'https://www.youtube.com/watch?v=jWOa3pSPMWI&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=13'
    },
    {
      name: 'Trend Micro',
      imageUrl: 'assets/antiviruses/trend-micro.png',
      link: 'https://www.youtube.com/watch?v=fp4nIQAta-4&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=11'
    },
    {
      name: 'Panda',
      imageUrl: 'assets/antiviruses/panda.png',
      link: 'https://www.youtube.com/watch?v=oLQ99yqGVMA&list=PLKq-5YE21jJW_MA1fFJ-P_pmOdEdx5e5v&index=12'
    }
  ];

  constructor() {}
}
