import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WhitelistedService } from '../../services/whitelisted.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DeleteAccountComponent } from 'src/app/pages/delete-account/delete-account.component';

@Component({
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account-modal.component.html',
  styleUrls: ['./delete-account-modal.component.scss']
})
export class DeleteAccountModalComponent implements OnInit {
  constructor(
    private activeModal: NgbActiveModal,
    private whiteListedService: WhitelistedService,
    private toastr: ToastrService,
    private http: HttpClient
  ) {}

  email: string = '';
  parentComponent?: DeleteAccountComponent;

  form: FormGroup = new FormGroup({
    confirmationCheckbox: new FormControl(false, [Validators.requiredTrue])
  });

  deleteUser(email: string) {
    const body = {
      email: email
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.request('DELETE', environment.gaiminApi + '/users', {
      headers: headers,
      body: body
    });
  }

  onSubmit() {
    this.closeModal();
    this.deleteUser(this.email).subscribe(
      (res) => {
        this.parentComponent?.clearInput();
        this.toastr.success('Request sent successfully. Check your email for further instructions.');
        console.log(res);
      },
      (err) => {
        this.toastr.error('Something went wrong');
        console.error(err);
      }
    );
  }

  closeModal() {
    this.activeModal.dismiss('Cross click');
  }

  ngOnInit(): void {}
}
