import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PhantomDeeplinksService {

  /**
   * Attempts to open the current web page in the Phantom wallet browser using a custom URL.
   */
  openInPhantom() {
    const url = this.buildUrl(window.location.href, window.location.origin);
    window.location.href = url;
  }

  /**
   * Constructs a custom URL to open the provided URL in the Phantom wallet browser, including a referral parameter.
   *
   * @param url {string} The URL of the current web page.
   * @param ref {string} The referral string to be included in the URL.
   * @returns {string} The constructed URL to open in Phantom wallet.
   */
  private buildUrl(url: string, ref: string): string {
    return `https://phantom.app/ul/browse/${encodeURIComponent(url)}?ref=${encodeURIComponent(ref)}`;
  }

}
