import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  WithdrawalConfirmationComponent
} from './pages/withdrawal-confirmation/withdrawal-confirmation.component';
import { GaiminGgComponent } from './pages/gaimin-gg/gaimin-gg.component';
import { GaiminTestersComponent } from './pages/gaimin-testers/gaimin-testers.component';
import { NftValidationComponent } from './pages/nft-validation/nft-validation.component';
import { HelpAntivirusComponent } from './pages/help-antivirus/help-antivirus.component';
import { SuccessPageComponent } from './pages/success-page/success-page.component';
import { FailedPageComponent } from './pages/failed-page/failed-page.component';
import { DeleteAccountComponent } from './pages/delete-account/delete-account.component';
import {
  SolanaWalletLoginComponent
} from './pages/solana-wallet-login/solana-wallet-login.component';
import {
  SolanaTransactionComponent
} from './pages/solana-transaction/solana-transaction.component';
import { SolanaClaimNftComponent } from './pages/solana-claim-nft/solana-claim-nft.component';
import {
  SolanaRewardsInfoComponent
} from './pages/solana-rewards-info/solana-rewards-info.component';
import { SolanaPfpNftComponent } from './pages/solana-pfp-nft/solana-pfp-nft.component';
import { EthereumGenesisComponent } from './pages/ethereum-genesis/ethereum-genesis.component';
import { CaptchaPageComponent } from './pages/captcha-page/captcha-page.component';

const routes: Routes = [
  { path: '', component: GaiminGgComponent },
  {
    path: 'testers',
    component: GaiminTestersComponent
  },
  {
    path: 'withdrawal',
    component: WithdrawalConfirmationComponent
  },
  {
    path: 'nft/validation',
    component: NftValidationComponent
  },
  {
    path: 'help/antivirus',
    component: HelpAntivirusComponent
  },
  {
    path: 'success',
    component: SuccessPageComponent
  },
  {
    path: 'failed',
    component: FailedPageComponent
  },
  {
    path: 'delete_my_account',
    component: DeleteAccountComponent
  },
  {
    path: 'solana-login',
    component: SolanaWalletLoginComponent
  },
  {
    path: 'solana-transaction',
    component: SolanaTransactionComponent
  },
  {
    path: 'ethereum/genesis',
    component: EthereumGenesisComponent
  },
  {
    path: 'solana-claim',
    component: SolanaClaimNftComponent
  },
  {
    path: 'solana/pfp/stake',
    component: SolanaPfpNftComponent
  },
  {
    path: 'solana-rewards-info',
    component: SolanaRewardsInfoComponent
  },
  {
    path: 'captcha',
    component: CaptchaPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
