import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WhitelistedService } from '../../shared/services/whitelisted.service';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-nft-validation',
  templateUrl: './nft-validation.component.html',
  styleUrls: ['./nft-validation.component.scss']
})
export class NftValidationComponent {
  form: FormGroup = new FormGroup({
    address: new FormControl('', [Validators.required])
  });
  isValid: boolean = false;
  nftDescription: string = '';

  constructor(private whiteListedService: WhitelistedService, private toastr: ToastrService) {}

  onSubmit() {
    this.nftDescription = '';

    const params = new HttpParams().append('address', this.form.value.address.trim());

    this.whiteListedService.checkNFT(params).subscribe(
      (response) => {
        if (response.success) {
          this.isValid = true;
          this.nftDescription = `Your Smart Contract address is a valid GAIMIN smart contract`;
        } else {
          if (response.error?.type == 'VALIDATION') {
            this.isValid = false;
            this.nftDescription = 'Your Smart Contract address is NOT a valid GAIMIN smart contract';
          } else {
            this.toastr.error(`${response.error?.description}`, 'Something went wrong!');
          }
        }
      },
      (error) => {
        this.toastr.error(error, 'Something went wrong!');
      }
    );
  }
}
