import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClaimedUserNFT, UserNFT } from '../../../shared/services/genesis/genesis.interfaces';

// For html template
interface MixedNftData extends ClaimedUserNFT, UserNFT {
}

@Component({
  selector: 'app-genesis-nft-card',
  templateUrl: './genesis-nft-card.component.html',
  styleUrls: ['./genesis-nft-card.component.scss']
})
export class GenesisNftCardComponent {
  @Input() nft!: Partial<MixedNftData>;

  @Input() isSelected: boolean = false;
  @Output() isSelectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleSelected() {
    this.isSelected = !this.isSelected;
    this.isSelectedChange.emit(this.isSelected);
  }
}
