import { Injectable } from '@angular/core';
import { WhitelistedModalComponent } from '../components/whitelisted-modal/whitelisted-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DeleteAccountModalComponent } from '../components/delete-account-modal/delete-account-modal.component';
import { ClaimRewardModalComponent } from '../components/claim-reward-modal/claim-reward-modal.component';
import { StakeConfirmationModalComponent } from '../components/stake-confirmation-modal/stake-confirmation-modal.component';
import { ApprovalClaimModalComponent } from '../components/approval-claim-modal/approval-claim-modal.component';
import { SuccessClaimModalComponent } from '../components/success-claim-modal/success-claim-modal.component';
import { SwitchChainModalComponent } from '../components/switch-chain-modal/switch-chain-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private ngbModalService: NgbModal) {}

  openApprovalClaimAllNftsModal() {
    this.ngbModalService.open(ApprovalClaimModalComponent, { centered: true, backdrop: 'static' });
  }

  openSwitchChainModal(chainName: string, chainId: number) {
    const modalRef: NgbModalRef = this.ngbModalService.open(SwitchChainModalComponent, {
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.chainName = chainName;
    modalRef.componentInstance.chainId = chainId;
  }

  openSuccessClaimModal(transactionHash: string) {
    const modalRef: NgbModalRef = this.ngbModalService.open(SuccessClaimModalComponent, { centered: true });
    modalRef.componentInstance.transactionHash = transactionHash;
  }

  openWhitelistedModal() {
    this.ngbModalService.open(WhitelistedModalComponent, { centered: true });
  }

  openAccountDeletionModal(email: string, componentRef: any) {
    const modalRef = this.ngbModalService.open(DeleteAccountModalComponent, { centered: true });
    modalRef.componentInstance.email = email;
    modalRef.componentInstance.parentComponent = componentRef;
  }

  openClaimModal() {
    const modalRef = this.ngbModalService.open(ClaimRewardModalComponent, {
      centered: true,
      backdropClass: 'claim-reward__backDrop'
    });
    modalRef.result
      .then((result) => {
        console.log(result);
        console.log('closed');
      })
      .catch((result) => {
        console.log(result);
        console.log('cancelling');
      });
  }

  openStakeConfirmationModal() {
    this.ngbModalService.open(StakeConfirmationModalComponent, { centered: true });
  }
}
