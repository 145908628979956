import { Component, OnDestroy, OnInit } from '@angular/core';
import { GenesisService } from '../../services/genesis/genesis.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-switch-chain-modal',
  templateUrl: './switch-chain-modal.component.html',
  styleUrls: ['./switch-chain-modal.component.scss']
})
export class SwitchChainModalComponent implements OnInit, OnDestroy {
  private activeChainIdSubscription?: Subscription;
  chainName!: number;
  chainId!: number;

  constructor(
    private genesisService: GenesisService,
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.activeChainIdSubscription = this.genesisService.activeChainId$.subscribe({
      next: (activeChainId) => {
        if (this.chainId === activeChainId) {
          this.closeModal();
        }
      }
    });
  }

  switchNetwork() {
    this.genesisService
      .switchNetwork()
      .then(() => {
        this.closeModal();
      })
      .catch((err) => {
        this.toastrService.error(err.message);
      });
  }

  closeModal() {
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.activeChainIdSubscription?.unsubscribe();
  }
}
