// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  min-height: 100%;
}

.failed-page-container {
  min-height: 100%;
  padding: 30px;
  display: flex;
  flex-flow: column;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background: #101011;
}
.failed-page-container .gaimin-logo {
  width: 100%;
  height: 200px;
  background: url('gaimin-logo.43a4ee08302e36a2.png') no-repeat center/contain;
}
.failed-page-container .title {
  margin: 30px 0 30px;
  color: #ef3333;
  font-size: 34px;
  font-weight: 500;
  text-align: center;
}
.failed-page-container .description {
  margin: 0 0 60px;
  color: #ef3333;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
}
.failed-page-container .footer {
  margin-top: auto;
  color: #585867;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/failed-page/failed-page.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,aAAA;EACA,iBAAA;EACA,kCAAA;EACA,WAAA;EACA,mBAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,4EAAA;AACJ;AAEE;EACE,mBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAIE;EACE,gBAAA;EACA,cAAA;EACA,kBAAA;AAFJ","sourcesContent":[":host {\n  width: 100%;\n  min-height: 100%;\n}\n\n.failed-page-container {\n  min-height: 100%;\n  padding: 30px;\n  display: flex;\n  flex-flow: column;\n  font-family: 'Poppins', sans-serif;\n  color: #fff;\n  background: #101011;\n\n  .gaimin-logo {\n    width: 100%;\n    height: 200px;\n    background: url('~src/assets/gaimin-logo.png') no-repeat center / contain;\n  }\n\n  .title {\n    margin: 30px 0 30px;\n    color: #ef3333;\n    font-size: 34px;\n    font-weight: 500;\n    text-align: center;\n  }\n\n  .description {\n    margin: 0 0 60px;\n    color: #ef3333;\n    font-size: 22px;\n    font-weight: 400;\n    text-align: center;\n  }\n\n  .footer {\n    margin-top: auto;\n    color: #585867;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
