import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SolanaClaimService } from '../../services/solana/solana-claim.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-claim-reward-modal',
  templateUrl: './claim-reward-modal.component.html',
  styleUrls: ['./claim-reward-modal.component.scss']
})
export class ClaimRewardModalComponent {
  form: FormGroup;


  constructor(private activeModal: NgbActiveModal,
              private solanaClaimService: SolanaClaimService,
              private utils: UtilsService
  ) {
    this.form = new FormGroup({
      wallet: new FormControl('', [Validators.required, this.utils.customBnbAddressValidator])
    });
  }

  async onSubmit() {
    if (this.form.valid) {
      const walletTo = this.form.value.wallet;
      const nftMintAddress = this.solanaClaimService.claimAllMintAddresses$.getValue();
      this.activeModal.close();
      await this.solanaClaimService.claimRewards(walletTo, nftMintAddress, true);
    }
  }

  closeModal() {
    this.activeModal.dismiss('Cross click');
  }

  onCancel() {
    this.closeModal();
    this.form.reset();
  }
}
