import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WhitelistedService } from '../../services/whitelisted.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-whitelisted-modal',
  templateUrl: './whitelisted-modal.component.html',
  styleUrls: ['./whitelisted-modal.component.scss']
})
export class WhitelistedModalComponent {
  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern('([A-Za-z- ]+)'), Validators.maxLength(30)]),
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(
    private activeModal: NgbActiveModal,
    private whiteListedService: WhitelistedService,
    private toastr: ToastrService
  ) {}

  onSubmit() {
    this.closeModal();
    this.whiteListedService.createLead(this.form.value).subscribe(
      (response) => {
        if (response.success) {
          this.toastr.success(
            'Please check your email right away, and see how you can move up the waiting list even faster.',
            'Awesome!'
          );
          console.log(response);
        } else {
          this.toastr.error(`${response.error?.description}`, 'Something went wrong');
        }
      },
      (error) => {
        console.log(error);
        this.toastr.error('Something went wrong');
      }
    );
  }

  closeModal() {
    this.activeModal.dismiss('Cross click');
  }
}
