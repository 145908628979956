import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PublicKey } from '@solana/web3.js';
import { SolanaParsersService } from '../../shared/services/solana/solana-parsers.service';
import { SolanaPdaService } from '../../shared/services/solana/solana-pda.service';
import { NftRecord } from '../../shared/services/solana/tools/solana.interfaces';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SolanaPfpService } from '../../shared/services/solana/solana-pfp.service';

@Component({
  selector: 'app-solana-rewards-info',
  templateUrl: './solana-rewards-info.component.html',
  styleUrls: ['./solana-rewards-info.component.scss']
})
export class SolanaRewardsInfoComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isValid: boolean = false;
  nftDescription: NftRecord | undefined;
  nftErrorDescription: string | undefined;
  private paramsSubsciption: Subscription | undefined;

  constructor(private solanaParsersService: SolanaParsersService,
              private solanaPdaService: SolanaPdaService,
              private solanaPfpService: SolanaPfpService,
              private fb: FormBuilder,
              private route: ActivatedRoute
  ) {
    this.form = this.fb.group({
      address: ['', [Validators.required]]
    });
  }


  ngOnInit() {
    this.paramsSubsciption = this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length > 0 && 'mintAddress' in params) {
        const initialValue = params['mintAddress'];
        this.form.get('address')?.setValue(initialValue);
        this.checkNftRewards(initialValue).then(nftRewards => {
          this.nftDescription = nftRewards;
          this.isValid = true;
        }).catch(error => {
          console.error(error);
          this.isValid = false;
          this.nftErrorDescription = error;
        });
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.checkNftRewards(this.form.value.address.trim()).then(nftRewards => {
        this.nftDescription = nftRewards;
        this.isValid = true;
      }).catch(error => {
        console.error(error);
        this.isValid = false;
        this.nftErrorDescription = error;
      });
    }
  }

  async checkNftRewards(nftMintAdress: string): Promise<NftRecord> {
    let nftDescription;
    const nftPda = this.solanaPdaService.getNftPda(new PublicKey(nftMintAdress))[0];
    const configPda = this.solanaPdaService.getConfigPda()[0];
    const config = await this.solanaParsersService
    .parseConfig(configPda);
    try {
      nftDescription = await this.solanaParsersService.parseNft(nftPda);
    } catch {
      nftDescription = {
        last_claimed_at: config.claimable_from,
        claimed_amount: 0,
        total_amount: config.accumulated_reward + config.initial_reward
      };
    }
    nftDescription.userReward = await this.solanaPfpService.estimateClaimReward(config, nftDescription);
    return nftDescription;
  }

  ngOnDestroy() {
    this.paramsSubsciption?.unsubscribe();
  }

}
