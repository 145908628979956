import { Injectable } from '@angular/core';
import { PublicKey, Transaction } from '@solana/web3.js';
import { SolanaService } from './solana.service';
import { ToastrService } from 'ngx-toastr';
import { WebSocketService } from '../web-socket.service';
import { SolanaConstantsService } from './tools/solana-constants.service';
import { SolanaInstructionService } from './solana-instruction.service';
import { BehaviorSubject } from 'rxjs';
import { SolanaPfpService } from './solana-pfp.service';
import { environment } from '../../../../environments/environment';
import { randomBytes } from 'tweetnacl';
import { ModalService } from '../modal.service';

@Injectable({
  providedIn: 'root'
})
export class SolanaClaimService {
  customWindow = (window as any);
  claimAllMintAddresses$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(private solanaService: SolanaService,
              private toastrService: ToastrService,
              private modalService: ModalService,
              private webSocketService: WebSocketService,
              private solanaInstructions: SolanaInstructionService,
              private solanaPfpService: SolanaPfpService,
              private solanaConstants: SolanaConstantsService) {
  }

  /**
   * Claims rewards for a given wallet address and NFT mint addresses.
   *
   * @param walletAddressTo - The wallet address to claim rewards to
   * @param nftMintAddresses - The NFT mint addresses to claim rewards from
   * @param triggerFromPFP - Optional flag to trigger from PFP-page
   *
   * @return {Promise<void>} - A promise that resolves when the rewards have been claimed
   */
  async claimRewards(walletAddressTo: string, nftMintAddresses: string | string[], triggerFromPFP?: boolean): Promise<void> {
    const transactions: Transaction[] = [];
    this.solanaService.isLoading.next(true);
    const socketMessage = 'Your claim is successful:';
    const transactionMessage = 'Your claim is confirmed. You may now close your browser';
    const publicKey = await this.solanaService.getUserPublicKey();
    const addressesToClaim = Array.isArray(nftMintAddresses) ? nftMintAddresses : [nftMintAddresses];
    const maxClaimInstructionsPerTransaction = 3;
    const maxRegisterNftInstructionsPerTransaction = 3;

    for (let i = 0; i < addressesToClaim.length; i += maxClaimInstructionsPerTransaction) {
      const transaction: Transaction = new Transaction();
      const randomByte = randomBytes(32);
      const claimSeed = Buffer.from(randomByte);
      transaction.feePayer = publicKey;
      transaction.add(this.solanaInstructions.createClaimInstruction(publicKey, walletAddressTo, claimSeed));

      const registerNftInstarctionToAdd = await Promise.all(
        addressesToClaim
        .slice(i, i + maxRegisterNftInstructionsPerTransaction)
        .map(async (nftMintAddress) => {
          transaction.recentBlockhash = (await this.solanaConstants.connection.getLatestBlockhash('finalized')).blockhash;
          return this.solanaInstructions.registerNftInstruction(publicKey, new PublicKey(nftMintAddress));
        })
      );

      const claimInstructionsToAdd = await Promise.all(
        addressesToClaim
        .slice(i, i + maxClaimInstructionsPerTransaction)
        .map(async (nftMintAddress) => {
          transaction.recentBlockhash = (await this.solanaConstants.connection.getLatestBlockhash('finalized')).blockhash;
          return this.solanaInstructions.claimInstruction(publicKey, new PublicKey(nftMintAddress), claimSeed);
        })
      );

      transaction.add(...registerNftInstarctionToAdd, ...claimInstructionsToAdd);
      transactions.push(transaction);
    }


    await this.sendAndSignTransaction(transactions, socketMessage, transactionMessage, triggerFromPFP)
    .catch((error) => {
      console.error('Error in claimRewards');
      this.solanaService.isLoading.next(false);
      this.webSocketService.sendMessage('Error: On claim rewards');
      this.handleTransactionError(error);
    });
  }

  /**
   * Delegates and stakes a specified list of NFT mints for the connected user or a provided public key.
   *
   * @param nftMintAddresses {string | string[]} A string representing a single NFT mint address, or an array of strings containing multiple NFT mint addresses.
   * @param userPublicKey {string} (Optional) The public key of the user for whom to perform staking (if different from the connected user).
   * @param triggerFromPFP {boolean} (Optional) A flag indicating if the call originates from the Gaimin PFP functionality.
   * @returns {Promise<void>} A promise that resolves upon successful delegation and staking, or rejects if an error occurs.
   */
  async delegateAndStake(nftMintAddresses: string | string[] | any[], userPublicKey?: string, triggerFromPFP?: boolean): Promise<void> {
    await this.processStakingOperations(
      'NFT successfully staked:',
      'Your nft is staked. You may now close your browser.',
      (publicKey, transaction, nftMint) => {
        transaction.add(
          this.solanaInstructions.delegateApproveInstruction(nftMint, publicKey, publicKey, publicKey),
          this.solanaInstructions.stakeInstruction(this.solanaConstants.MPL_INSTRUCTIONS.lock, nftMint, publicKey, publicKey, publicKey)
        );
      },
      nftMintAddresses,
      userPublicKey,
      triggerFromPFP
    );
  }

  /**
   * Revokes delegation and unstakes a specified list of NFT mints for the connected user or a provided public key.
   *
   * @param nftMintAddresses {string | string[]} A string representing a single NFT mint address, or an array of strings containing multiple NFT mint addresses.
   * @param userPublicKey {string} (Optional) The public key of the user for whom to perform unstaking (if different from the connected user).
   * @param triggerFromPFP {boolean} (Optional) A flag indicating if the call originates from the Gaimin PFP functionality.
   * @returns {Promise<void>} A promise that resolves upon successful delegation revocation and unstaking, or rejects if an error occurs.
   */
  async revokeAndUnstake(nftMintAddresses: string | string[] | any[], userPublicKey?: string, triggerFromPFP?: boolean): Promise<void> {
    await this.processStakingOperations(
      'NFT successfully unstaked:',
      'Your nft is unlocked. You may now close your browser.',
      (publicKey, transaction, nftMint) => {
        transaction.add(
          this.solanaInstructions.stakeInstruction(this.solanaConstants.MPL_INSTRUCTIONS.unlock, nftMint, publicKey, publicKey, publicKey),
          this.solanaInstructions.delegateRevokeInstruction(nftMint, publicKey, publicKey, publicKey)
        );
      },
      nftMintAddresses,
      userPublicKey,
      triggerFromPFP
    );
  }

  /**
   * Processes delegation and staking (or revocation and unstaking) operations for a list of NFT mints.
   *
   * @param socketMessage {string} The message to send to the WebSocket upon successful operation.
   * @param transactionMessage {string} The message to display after transaction confirmation.
   * @param operationFunction {function} A function that performs the specific delegation/staking or revocation/unstaking operation for each NFT mint.
   * @param nftMintAddresses {string | string[]} A string representing a single NFT mint address, or an array of strings containing multiple NFT mint addresses.
   * @param userPublicKey {string} (Optional) The public key of the user for whom to perform the operation (if different from the connected user).
   * @param triggerFromPFP {boolean} (Optional) A flag indicating if the call originates from the Gaimin PFP functionality.
   * @returns {Promise<void>} A promise that resolves upon successful operation, or rejects if an error occurs.
   */
  private async processStakingOperations(socketMessage: string, transactionMessage: string,
                                         operationFunction: (publicKey: PublicKey, transaction: Transaction, nftMint: PublicKey, walletAddressTo?: string) => void,
                                         nftMintAddresses: string | string[], userPublicKey?: string, triggerFromPFP?: boolean): Promise<void> {
    this.solanaService.isLoading.next(true);
    this.solanaPfpService.isNftLoading = true;
    try {
      let publicKey = userPublicKey ? new PublicKey(userPublicKey) : await this.solanaService.getUserPublicKey();
      const transactions: Transaction[] = [];
      const addressesToProcess = Array.isArray(nftMintAddresses) ? nftMintAddresses : [nftMintAddresses];
      const maxInstructionsPerTransaction = 4;

      for (let i = 0; i < addressesToProcess.length; i += maxInstructionsPerTransaction) {
        const transaction: Transaction = new Transaction();
        transaction.recentBlockhash = (await this.solanaConstants.connection.getLatestBlockhash('finalized')).blockhash;
        transaction.feePayer = publicKey;

        const nftMintsSlice = addressesToProcess.slice(i, i + maxInstructionsPerTransaction);
        for (const nftMintAddress of nftMintsSlice) {
          const nftMint: PublicKey = new PublicKey(nftMintAddress);
          operationFunction(publicKey, transaction, nftMint);
        }

        transactions.push(transaction);
      }

      await this.sendAndSignTransaction(transactions, socketMessage, transactionMessage, triggerFromPFP);
    } catch (error) {
      this.handleTransactionError(error);
    }
  }


  /**
   * Handles errors that occur during transaction processing.
   *
   * @param error {Error} The error object.
   */
  private handleTransactionError(error: any) {
    this.solanaService.isLoading.next(false);
    this.solanaPfpService.isNftLoading = false;
    console.error('PhantomTransaction error', error);
    this.toastrService.error(error?.message, 'Error');
    this.webSocketService.sendMessage(`Error: ${error?.message}`);
    this.solanaService.infoClaimMessage = 'Something went wrong. Please try again later.';
    if (error?.message.includes('reject')) {
      this.solanaService.infoClaimMessage = error?.message;
    }
  }

  /**
   * Sends and signs a list of transactions, displays success/error messages, and updates loading states.
   *
   * @param transactions {Transaction[]} An array of Transaction objects to be sent and signed.
   * @param socketMessage {string} The message to send to the WebSocket upon successful operation.
   * @param transactionMessage {string} The message to display after transaction confirmation.
   * @param triggerFromPFP {boolean} (Optional) A flag indicating if the call originates from the Gaimin PFP functionality.
   * @returns {Promise<void>} A promise that resolves upon successful transaction processing, or rejects if an error occurs.
   */
  private async sendAndSignTransaction(transactions: Transaction[],
                                       socketMessage: string, transactionMessage: string, triggerFromPFP?: boolean): Promise<void> {
    let isErrorThrown = false;
    try {
      const signedTransactions: Transaction[] = await this.customWindow.phantom?.solana.signAllTransactions(transactions);
      const signatures: string[] = [];
      try {
        const sendPromises: Promise<string | null>[] = [];
        for (const signedTransaction of signedTransactions) {
          if (signedTransaction) {
            sendPromises.push(
              new Promise<string | null>((resolve) => {
                setTimeout(async () => {
                  try {
                    const signature = await this.solanaConstants.connection.sendRawTransaction(
                      signedTransaction.serialize()
                    );
                    console.log('Transaction Signature:', signature);
                    resolve(signature);
                  } catch (sendError) {
                    isErrorThrown = true;
                    this.handleTransactionError(sendError);
                    resolve(null);
                  }
                }, 1000);
              })
            );
          }
        }

        const userWallet = await this.solanaService.getUserPublicKey();
        const sentSignatures = await Promise.all(sendPromises);
        const filteredSignatures = sentSignatures.filter((signature): signature is string => signature !== null);

        signatures.push(...filteredSignatures);

        if (!isErrorThrown) {
          let message = '';
          let toastrLink = '';

          if (signatures.length > 1) {
            message = `${socketMessage + 'account'} ${userWallet}`;
            toastrLink = `account ${userWallet}`;
          } else if (signatures.length !== 0) {
            message = `${socketMessage} ${signatures.join(', ')}`;
            toastrLink = `${signatures.join(', ')}`;
          }

          if (message && toastrLink) {
            this.webSocketService.sendMessage(message);
            this.showToastrWithLink(toastrLink);
          }


          if (triggerFromPFP) {
            await this.solanaPfpService.refreshData(userWallet);
            if (signatures && socketMessage.includes('Your claim is successful')) {
              this.modalService.openStakeConfirmationModal();
            }
          }
        }

      } catch (error) {
        this.handleTransactionError(error);
      }

      this.solanaService.isLoading.next(false);
      this.solanaPfpService.isNftLoading = false;
      this.solanaService.infoClaimMessage = transactionMessage;
    } catch (error) {
      this.handleTransactionError(error);
    } finally {
      this.solanaService.isLoading.next(false);
    }
  }

  /**
   * Parses a WebSocket message to extract the wallet address and NFT mint addresses.
   *
   * @param message {string} The WebSocket message to parse.
   * @returns {object} An object containing the extracted wallet address and NFT mint addresses:
   *   - walletAddress: The wallet address found in the message (if any).
   *   - nftMintAddresses: An array of NFT mint addresses found in the message.
   */
  extractMessageFromWebSocket(message: string) {
    const walletAddressMatch = RegExp(/walletAddressTo:\s*('?\w+'?)/).exec(message);
    const nftMintAddressMatch = RegExp(/nftMintAddress:\s*('?\w+'?(?:\s*,\s*'?\w+'?)*)/).exec(message);

    const extractAddresses = (match: string[] | null) => {
      if (match) {
        const rawAddresses = match[1].split(',').map((address: string) => address.trim().replace(/'/g, ''));
        return rawAddresses.filter((address: string) => address !== '');
      }
      return [];
    };

    return {
      walletAddress: walletAddressMatch ? walletAddressMatch[1] : '',
      nftMintAddresses: extractAddresses(nftMintAddressMatch)
    };
  }


  /**
   * Displays a toast message with a link to explore a transaction on Solflare.
   *
   * @param transactionHash {string} The transaction hash to link to.
   */
  private showToastrWithLink(transactionHash: string) {
    let baseUrl;
    if (transactionHash.includes('account')) {
      baseUrl = 'https://solscan.io/account/';
      transactionHash = transactionHash.replace('account', '').trim();
    } else {
      baseUrl = 'https://solscan.io/tx/';
    }

    const encodedTransactionHash = encodeURIComponent(transactionHash);
    const link = `${baseUrl}${encodedTransactionHash}?cluster=${environment.solana.solanaCluster}`;
    const message = `<div class="toast-custom-solana">The transaction is confirmed. View on Explore<br>
                          <a href="${link}" target="_blank">${transactionHash}</a></div>`;
    const title = 'Confirmed transaction.';

    this.toastrService.success(message, title, {
      enableHtml: true,
      closeButton: true,
      extendedTimeOut: 15000
    });
  }


}
